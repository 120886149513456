import React, { useEffect, useRef, useState } from 'react';
import './Request.scss';
import LockIcon from '../svgIcons/LockIcon';
import NewLoginLogo from '../../assets/images/new_builder_logo_login.svg';
import AccessSuccesful from '../../assets/images/done.png';
import LoadingScreen from '../loader/Loading';
import ArrowLeft from '../svgIcons/arrows/ArrowLeft';
import { ClipLoader } from 'react-spinners';
import { checkRequestAccess, sendRequestAccess } from '../../services/RequestAccessService';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { SOCKET_STATUS_MODS } from '../../helpers/Constant';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { environment } from '../../environment';
import { deleteCookie } from '../../services/CookieService';
import store from '../../redux/Store';
import { loggedInUser } from '../../services/AuthService';
import getToastIcon from '../../helpers/media/GetToastIcon';
import { ToastContainer, toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/UseDocumentTitle';

const initialRequestInfo = { ownerEmail: '', guestEmail: '', guestId: '' }

const CreateRequest = () => {
    const dispatch = useDispatch();
    const textareaRef = useRef();
    const socketRef = useRef();
    const [requestText, setRequestText] = useState('');
    const [requestInfo, setRequestInfo] = useState(initialRequestInfo);
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [isLoading, setIsLoading] = useState('pageLoading');

    const navigate = useNavigate();
    const location = useLocation();
    useDocumentTitle({});

    const whiteBoardSlugId = location.pathname.split('/')[2];

    const handleLogout = () => {
        deleteCookie();
        store.dispatch({ type: 'user/setNull', payload: {} });
        navigate('/');
    }

    /**
     * @param {'focus' | 'blur'} value 
     */
    const handleTextareaEffect = (value) => {
        const textareaSpan = document.querySelector('.textarea-span')
        if (value === 'focus') {
            textareaSpan.innerHTML = 'Add message';
            textareaSpan.classList.add('active');
        }
        else if (requestText === '') {
            textareaSpan.innerHTML = 'Add a message to owner';
            textareaSpan.classList.remove('active');
        }
    }
    
    /**
     * 
     * @param {Event} event
     */
    const handleMessageChange = (event) => {
        let value = event.target.value;
        if (value.trim().length > 200) {
            setRequestText(value.trim().substring(0, 200))
            return
        }
        setRequestText(value);
    }
    
    const handleRequestCreation = async () => {
        let isError = false;
        setIsLoading('requestSending');
        
        try {
            const response = await sendRequestAccess(whiteBoardSlugId, requestText.trim());

            if (response?.success) {
                setIsLoading('requestSuccessful');
                setRequestText('');
            }
        }
        catch (err) {
            isError = true;
            console.log(err)
            setIsLoading('createRequest');
        }
        finally {
            if (isError) {
                setIsLoading('createRequest');
            }
        }
    }

    /**
     * @param {string=} message 
     */
    const handleDeletedBoardResponse = (message) => {
        setTimeout(() => toast.error((
            <>
                {message ?? 'This whiteboard has been deleted by the owner.'}
            </>
        ), {
            toastId: 'board-deleted',
            closeOnClick: false,
            draggable: false,
            hideProgressBar: true,
            closeButton: true,
            position: 'bottom-center',
            className: 'wb_toast',
            icon: getToastIcon('error')
        }), 1200);
        navigate('/boards');
    }

    useEffect(() => {
        if (socketRef.current) socketRef.current.on('responseAccess', (data) => {
            if (data?.accepted) navigate(`/board/${data?.wbId ?? whiteBoardSlugId}`)
            else if (data?.pending) setIsLoading('requestSuccessful');
            else if (data?.deleted) handleDeletedBoardResponse(data.message);
            else setIsLoading('createRequest');
        });
    }, [isSocketConnected]);

    /**
     * @param {object} data 
     */
    const connectToSocket = (data) => {
        if (whiteBoardSlugId && !isSocketConnected) {
            socketRef.current = io(environment.REACT_APP_API_ENDPOINT, {
                transports: ['websocket'], // use WebSocket first, if available
            });

            socketRef.current.on('connect', () => {
                let body = { roomId: data.id, userId: data.guestId };

                socketRef.current.emit('request-access', body, () => {
                    dispatch({
                        type: 'socket/changeMode',
                        payload: SOCKET_STATUS_MODS.CONNECTED
                    });
                });
            });
            socketRef.current.on('disconnect', (reason) => {
                // if the user is disconnected by unmointing this component, we dont need to show the overlay
                if (reason && reason === 'io client disconnect') {
                    return;
                }
                dispatch({
                    type: 'socket/changeMode',
                    payload: SOCKET_STATUS_MODS.DISCONNECTED
                });
            });
            socketRef.current.io.on('reconnect_attempt', () => {
                dispatch({
                    type: 'socket/changeMode',
                    payload: SOCKET_STATUS_MODS.RECONNECT_ATTEMPT
                });
            });

            setIsSocketConnected(true);
        }
    }

    (0, useEffect)(() => { 
        const handleCheckRequestAccess = async () => {
            let isError = false;
            try {
                const response = await checkRequestAccess(whiteBoardSlugId);
                const data = response.data;
                if (response?.success) {
                    let activeScreen = ''
                    if (data.status === 'invited') {
                        navigate(`/board/${data.wbId ?? whiteBoardSlugId}`)
                    }
                    else if (data.status === 'no-access') {
                        activeScreen = 'createRequest'
                        connectToSocket(data);
                    }
                    else if (data.status === 'pending') {
                        activeScreen = 'requestSuccessful';
                        setRequestText('');
                        connectToSocket(data);
                    }
                    else if (data.status === 'deleted') {
                        handleDeletedBoardResponse();
                    }
                    dispatch({
                        type: 'board/changeBoardDetails',
                        payload: {boardName: data.name}
                    })
                    setRequestInfo(data)
                    setIsLoading(activeScreen);
                }
                else if ([404, 401].includes(response?.response?.status) || response?.statusCode === 401) {
                    navigate('/boards')
                    setTimeout(() => toast.error((
                        <>
                            {response?.response?.data?.error ?? response?.message}
                        </>
                    ), {
                        toastId: 'board-not-found',
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        closeButton: true,
                        position: 'bottom-center',
                        className: 'wb_toast',
                        icon: getToastIcon('error')
                    }), 1000);
                }
            }
            catch (err) {
                isError = true;
                console.log(err)
                setIsLoading('createRequest');
            }
            finally {
                if (isError) {
                    setIsLoading('createRequest');
                }
            }
        } 

        const loggedUser = loggedInUser();
        if (!loggedUser) {
            navigate({
                pathname: '/',
                search: createSearchParams({
                    callback: encodeURIComponent(`/board/${whiteBoardSlugId}`),
                }).toString()
            })
        }
        else handleCheckRequestAccess();
    }, [whiteBoardSlugId])

    useEffect(() => {
        return () => {
            setRequestText('');
            dispatch({type: 'board/resetBoardState'})
            // handle disconnecting socket on unmount
            if (socketRef?.current) {
                try {
                    socketRef?.current?.disconnect();
                } catch (e) {
                    console.error('error while disconnecting', e)
                }
            }
        }
    }, [])

    return (
        <>
            <div className='request--wrapper'>
                {
                    isLoading === 'pageLoading' ?
                        <LoadingScreen /> : null
                }
                <>
                    {
                        (isLoading === 'createRequest' || isLoading === 'requestSending') ?
                            <div className='request--container'>
                                <LockIcon />
                                <span className='request--access-text'>Request access</span>
                                <span className='request--board-owner-text'><span>Owner </span> <span>{requestInfo.ownerEmail}</span></span>
                                <div onClick={() => textareaRef?.current?.focus()} className='request--textarea-wrapper'>
                                    <textarea ref={textareaRef} disabled={isLoading === 'requestSending'} rows={2} cols={2} value={requestText} onChange={handleMessageChange} onFocus={() => handleTextareaEffect('focus')} onBlur={() => handleTextareaEffect('blur')}></textarea>
                                    <span className='textarea-span'>Add a message to the owner</span>
                                    <span className="length-indicator"> {requestText.trim().length} / 200 </span>
                                </div>
                                <button disabled={isLoading === 'requestSending'} onClick={handleRequestCreation} className='request--button' type='submit'>
                                    <ClipLoader color="#fff" loading={isLoading === 'requestSending'} size={22} /> {isLoading !== 'requestSending' ? 'Request access' : ''}
                                </button>
                                <span className='request--accountOwner-text'>
                                    <div>
                                        <span>You’re logged in as</span>
                                        <span className='request--accountOwner-text-mail'> {requestInfo.guestEmail}</span>
                                    </div>
                                    <span onClick={handleLogout} className='request--accountOwner-text-switchAccount'>
                                        Switch account
                                        <span className='warning-tooltip'>
                                            You’ll be logged out of this account and redirected to Sign-in page
                                        </span>
                                    </span>

                                </span>
                            </div> : null
                    }
                    {
                        isLoading === 'requestSuccessful'  ?
                            <div className='request--container access'>
                                <img className='request--access-successful' src={AccessSuccesful} alt='Access sent' />
                                <span className='request--access-text'>Access request sent</span>
                                <span onClick={()=>navigate('/boards')} className='request--backToDashboard'><ArrowLeft /> <span>Back to dashboard</span></span>
                            </div> : null
                    }
                    <img onClick={()=> navigate('/')} className='request--logo' alt='Logo' src={NewLoginLogo} />
                </>
            </div>
            <ToastContainer closeOnClick={false} position='bottom-center' />
        </>
    );
}

export default CreateRequest;