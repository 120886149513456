import {fabric} from 'fabric';

/**
 * Calculates some properties for groups. This is useful for some scenarios.
 * Getting accurate position when the object is in the group.
 * Getting rotate and scale properties while the group is getting changed: Since fabric js does not
 * apply these properties to the group objects until mouse up event.
 * @param {fabric.Group} group Parent group object. This will be fabric.ActiveSelection in most cases.
 * @returns {{scaleX: (number|*|number), scaleY: (number|*|number), top, left, angle: *}} Accurate properties of this object.
 */
export function getCalculatedQRDecompose (group) {
    const groupMatrix = group.calcOwnMatrix()
    const objectMatrix = this.calcOwnMatrix()
    const matrix = fabric.util.multiplyTransformMatrices(groupMatrix, objectMatrix)
    const qrDecompose = fabric.util.qrDecompose(matrix)

    const center = this.translateToCenterPoint(
        new fabric.Point(qrDecompose.translateX, qrDecompose.translateY),
        'center', 'center');

    let position = this.translateToGivenOrigin(center, 'center', 'center', this.originX, this.originY);
    if (qrDecompose.angle) {
        position = fabric.util.rotatePoint(position, center, fabric.util.degreesToRadians(qrDecompose.angle));
    }

    return {
        left: position.x,
        top: position.y,
        scaleX: this._constrainScale(qrDecompose.scaleX),
        scaleY: this._constrainScale(qrDecompose.scaleY),
        angle: qrDecompose.angle
    }
}