/* This file contains event handlers for object transformations,
* specifically rotating and scaling actions.
* It handles adjustments to connected lines, during
* these transformations for both shapes, images and stick notes.
*/

import { fabric } from 'fabric';
import { getAttachedPointOfLine } from './LineMethods';
import {calculateObjectCenterPoint} from '../FabricMethods';

/**
 * @param e
 * @param canvas
 */
export function handleObjectRotating (e, canvas)  {
    const target = e.target;
    
    const handleSingleObjectRotation = (target, group) => {
        const rectCenter = group ? calculateObjectCenterPoint(target) : target.getCenterPoint();
        let angle = target.angle;
        if (group) {
            const matrix = target.calcTransformMatrix();
            const coords = fabric.util.qrDecompose(matrix);
            rectCenter.x = coords.translateX;
            rectCenter.y = coords.translateY;
            
            // calculate object angle since it's updated by group
            angle = (target.angle + group.angle) % 360
        }
        try {
            target.lines.forEach(lineUuid => {
                const lineInCanvas = canvas.getObjects().find(o => o.uuid === lineUuid);
                if (!lineInCanvas) return;
                const attachedPoint = getAttachedPointOfLine(lineInCanvas, target);
                // if line is not attached to this target, skip
                if (!attachedPoint) return;
                lineInCanvas.moveEnd(target, rectCenter, attachedPoint, { angle })
            });
        } catch (error) {
            console.error(error);
        } 
    }
 
    if (target.type === 'activeSelection') {
        target.getObjects().forEach(object => {
            if (!object?.lines?.length) {
                return
            }
            handleSingleObjectRotation(object, target)
        })
    } else if (target.lines?.length) {
        handleSingleObjectRotation(target)
    }
}

/**
 * Rotates a point back to its original position .
 * @param {float} x - Rotated x.
 * @param {float} y - Rotated y.
 * @param {float} cx - Center of rotation x.
 * @param {float} cy - Center of rotation y.
 * @param {float} angleInDegrees - Rotation angle in degrees.
 * @returns 
 */
export function rotatePointBack(x, y, cx, cy, angleInDegrees) {
    // use radians
    const angleInRadians = -angleInDegrees * (Math.PI / 180);

    // find the original position relative to the center
    let offsetX = x - cx;
    let offsetY = y - cy;

    // rotate back the point
    let rotatedX = offsetX * Math.cos(angleInRadians) - offsetY * Math.sin(angleInRadians);
    let rotatedY = offsetX * Math.sin(angleInRadians) + offsetY * Math.cos(angleInRadians);

    // move the point back to its original position
    let originalX = rotatedX + cx;
    let originalY = rotatedY + cy;

    return { x: originalX, y: originalY };
}

export const handleObjectScalingAndResizing = (e, canvas) => {
    const target = e.target;
    const handleSingleObject = (target, group) => {
        try {
            const center = group ? calculateObjectCenterPoint(target) : target.getCenterPoint();
            const matrix = target.calcTransformMatrix();
            const coords = fabric.util.qrDecompose(matrix);
            center.x = coords.translateX;
            center.y = coords.translateY;

            for (const lineUuid of target.lines) {
                const lineInCanvas = canvas.getObjects().find(o => o.uuid === lineUuid);
                if (!lineInCanvas) continue;
                const attachedPoint = getAttachedPointOfLine(lineInCanvas, target);
                // if line is not attached to this target, skip

                if (!attachedPoint) continue;
                
                // since group is scaling, we need to calculate accurate polygon width
                lineInCanvas.moveEnd(target, center, attachedPoint, {
                    polygonWidth: target.width * target.scaleX * (group ? group.scaleX : 1),
                    polygonHeight: target.height * target.scaleY * ( group ? group.scaleY : 1),
                })
            }
        } catch(error) {
            console.error(error);
        } 
    }
    if (e.target.type === 'activeSelection') {
        e.target.getObjects().forEach(object => {
            if (!object.lines?.length) {
                return
            }
            handleSingleObject(object, target);
        })
    }
    if (target.lines?.length) {
        handleSingleObject(target)
    } 
}