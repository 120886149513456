import { fabric } from 'fabric';
import {customToObject} from '../FabricMethods';

export class LinePolygonUpdater {
    constructor(canvas, updaterFunc, shapeCount) {
        this.fabricCanvas = canvas;
        this.lines = [];
        this.oldLineDataForRelative = [];
        this.linesForRelativePositionsUpdate = [];
        this.updaterFunc = updaterFunc;
        this.maxShapeCount = shapeCount;
        this.addedShapeCount = 0;
        this.relativeDataUpdateTimeout = null;
    }
    addedShape() {
        this.addedShapeCount++;
        if (this.addedShapeCount >= this.maxShapeCount) {
            this.emit()
        }
    }
    add(data) {
        if (!data.properties || data.properties?.type !== 'curvedLine') {
            return
        }
        this.checkDelta(data);
        
        if (!data.properties.leftPolygon && !data.properties.rightPolygon) {
            return
        }
        
        if (
            typeof data.properties.leftPolygon === 'string' &&
            typeof data.properties.rightPolygon === 'string'
        ) {
            return
        }
        
        let isUpdated = false;
        
        if (data.properties.leftPolygon && typeof data.properties.leftPolygon !== 'string') {
            isUpdated = true
            data.properties.leftPolygon = data?.properties?.leftPolygon?.uuid;
        }
        
        if (data.properties.rightPolygon && typeof data.properties.rightPolygon !== 'string') {
            isUpdated = true
            data.properties.rightPolygon = data?.properties?.rightPolygon?.uuid;
        }
        
        if (!isUpdated) {
            return 
        }
        
        this.lines.push(data)
    }
    
    checkDelta(data) {
        let shouldUpdate = false;
        
        const leftRelativeX = data?.properties?.leftRelativeX
        const leftRelativeY = data?.properties?.leftRelativeY
        const rightRelativeX = data?.properties?.rightRelativeX
        const rightRelativeY = data?.properties?.rightRelativeY
        
        if (
            (
                data?.properties?.leftDeltaX !== null && data?.properties?.leftDeltaX !== undefined ||
                data?.properties?.leftDeltaY !== null && data?.properties?.leftDeltaY !== undefined
            ) 
            &&
            (
                (typeof leftRelativeX === 'undefined' || leftRelativeX === null || leftRelativeX === undefined) ||
                (typeof leftRelativeY === 'undefined' || leftRelativeY === null || leftRelativeY === undefined)
            )
        ) {
            shouldUpdate = true
        }

        if (
            (
                data?.properties?.rightDeltaX !== null && data?.properties?.rightDeltaX !== undefined ||
                data?.properties?.rightDeltaY !== null && data?.properties?.rightDeltaY !== undefined 
            )
            &&
            (
                (typeof rightRelativeX === 'undefined' || rightRelativeX === null || rightRelativeX === undefined) ||
                (typeof rightRelativeY === 'undefined' || rightRelativeY === null || rightRelativeY === undefined)
            )
        ) {
            shouldUpdate = true
        }
        
        if (shouldUpdate) {
            this.oldLineDataForRelative.push(data)
        }
    }
    
    emit() {
        if (this.lines.length) {
            this.updaterFunc && this.updaterFunc({action: 'polygonUpdate', lines: this.lines})
        }
    }

    /**
     * Saves updated line relative points to db.
     * @param {fabric.CurvedLine} line The Line whose relative data will be saved.
     */
    updateLineForRelative(line) {
        this.linesForRelativePositionsUpdate.push({line, isUpdated: false, isError: false, errorMsg: ''})
        clearTimeout(this.relativeDataUpdateTimeout)
        
        this.relativeDataUpdateTimeout = setTimeout(() => {
            this.emitRelativeLineUpdates()
        }, 1500)
    }

    /**
     * Emits updated line relative position.  
     */
    emitRelativeLineUpdates() {
        const updatedLineData = []
        for (const data of this.linesForRelativePositionsUpdate) {
            if (data.isUpdated) {
                continue
            }
            try {
                const { line } = data;
                const oldData = this.oldLineDataForRelative.find(oldLineData => oldLineData?.uuid === line.uuid)
                if (!oldData) {
                    data.isError = true
                    data.errorMsg = 'Could not find the line in old data'
                    continue
                }

                data.isUpdated = true;
                updatedLineData.push({
                    ...oldData,
                    properties: customToObject(line, { useCalculatedPosition: true, narrowDefinitions: true })
                }) 
            } catch (err) {
                data.isUpdated = false;
                data.isError = true;
                data.errorMsg = err;
            }
        }

        (updatedLineData.length && this.updaterFunc) && this.updaterFunc({action: 'deltaUpdate', lines: updatedLineData})
        
        if (updatedLineData.length !== this.linesForRelativePositionsUpdate?.length) {
            console.error(
                '>> error while updating relative data below lines', 
                this.linesForRelativePositionsUpdate.filter(data => data.isError)
            )
        }
        
        // clear data after 5 seconds
        clearTimeout(this.clearRelativeLineDataTimeout)
        this.clearRelativeLineDataTimeout = setTimeout(() => {
            this.linesForRelativePositionsUpdate.length = 0;
            this.oldLineDataForRelative.length = 0;
        }, 5000)
    }
}