import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getActiveWord, getPlainContent, replaceAt} from '../../../../helpers/mentions';
import { changeTextareaHeight } from '../../../../helpers/Textarea';
import MentionBox from '../../../mentionBox/MentionBox';

import './CommentEditBox.scss';

/**
 * Edit box for comments.
 * @param {object} props
 * @param props.defaultValue
 * @param props.users
 * @param props.defaultTags
 * @param props.closeEditBox
 * @param props.handleEditEmit
 * @param props.contentRef
 * @param props.wrapperRef
 */
const CommentEditBox = ({ defaultValue, users, defaultTags, closeEditBox, handleEditEmit, contentRef, wrapperRef }) => {
    const [value, setValue] = useState(defaultValue);
    const [oldValue, setOldValue] = useState(defaultValue);
    const [visibleValue, setVisibleValue] = useState(getPlainContent(defaultValue));
    const [queryFilter, setQueryFilter] = useState('');
    const [showMentions, setShowMentions] = useState(false);
    const [activeWord, setActiveWord] = useState({});
    const [newActiveWord, setNewActiveWord] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [taggedUsers, setTaggedUsers] = useState(defaultTags);
    const editInputRef = useRef();
  
    const closeMentions = () => setShowMentions(false);

    const handleKeyDown = e => {
        // shiftEnter emits edit
        if (!showMentions && e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!disabled) handleUpdate();
        }
        if (e.key === 'Escape') {
            if (showMentions) setShowMentions(false);
            closeEditBox();
        }
    }
    
    const getNewIndex = () => {
        const [index] = newActiveWord.range;
        
        return index;
    }

    const handleSelect = (obj) => {
        setShowMentions(false);
    
        const [index] = activeWord.range;
        const replacement = `@${obj.commentUsername}`;
        const newReplacement = `@${obj.id}`;
    
        const replacedText = replaceAt(
            visibleValue,
            replacement,
            index,
            activeWord.word.length
        )
    
        setVisibleValue(replacedText);
        if (editInputRef.current) {
            setTimeout(() => {
                editInputRef.current.selectionEnd = index + replacement.length;
                editInputRef.current.focus();
                setVisibleValue(replacedText + ' ');
            }, 0);
        }
    
        const { name, id, commentUsername } = obj;
        setTaggedUsers([
            ...taggedUsers, {
                name,
                commentUsername,
                id,
                range: [index, index + replacement.length],
                newRange: [getNewIndex(), getNewIndex() + newReplacement.length],
                fullText: replacement,
                newFullText: newReplacement,
                index: activeWord.index
            }
        ]);
    }

    const handleChange = e => {
        let inputVal = e.target.value;
        const selectionStart = e.target.selectionStart;
        let {activeWord, newActiveWord} = getActiveWord(inputVal, taggedUsers, selectionStart);
        if (activeWord && activeWord.word.startsWith('@')) {
            let query = activeWord.word.substring(1);
            setQueryFilter(query);
            setActiveWord(activeWord);
            setNewActiveWord(newActiveWord);
            setShowMentions(true);
        } else {
            setQueryFilter('');
            setShowMentions(false);
        }
    
        setVisibleValue(inputVal);
        
        if (Array.isArray(taggedUsers) && taggedUsers.length > 0) {
            let tempAddedLinks = [...taggedUsers];
            for (let i = 0; i < tempAddedLinks.length; i++ ) {
                const element = tempAddedLinks[i];
                if (inputVal.substring(element.range[0], element.range[1]) !== element.fullText) {
                    tempAddedLinks.splice(i);
                }
            }
            setTaggedUsers(tempAddedLinks);
        } else {
            setTaggedUsers([]);
        }
        changeTextareaHeight(editInputRef.current, inputVal);
    }

    const handleUpdate = () => {
        let payload = {
            value,
            oldValue,
            taggedUserIds: taggedUsers
        }
        handleEditEmit(payload)
    }

    (0, useEffect)(() => {
        let editInputElement = editInputRef.current;
        editInputElement.focus();
        editInputElement.selectionStart = visibleValue.length;
        editInputElement.selectionEnd = visibleValue.length;
        editInputElement.style.height = contentRef.current.offsetHeight + 'px';

        if (defaultTags) setTaggedUsers(defaultTags);
    }, [])


    useEffect(() => {
        if (taggedUsers && taggedUsers.length > 0) {
            let content = visibleValue;
            let oldContent = visibleValue;
            for (const element of taggedUsers) {
                //old way
                // eslint-disable-next-line no-useless-escape
                const pattern = new RegExp('@'+element.commentUsername.replaceAll('.', '\.'), 'gi');
                oldContent = oldContent.replace(pattern, `@[${element.commentUsername}](${element.id})`);
                
                //new way
                content = content.replace(pattern, `@${element.id}@`);
            }
            setValue(content);
            setOldValue(oldContent);
        } else {
            setValue(visibleValue);
            setOldValue(visibleValue);
        }
        if (visibleValue.length > 0) setDisabled(false);
        else setDisabled(true);
    }, [visibleValue, taggedUsers]);

    return (
        <>
            <main className="editInput__wrapper">
                {showMentions ? <MentionBox
                    addComment={handleUpdate}
                    closeMentions={closeMentions}
                    customPosElementRef={editInputRef}
                    filter={queryFilter}
                    handleSelectCallback={handleSelect}
                    users={users}
                    wrapperRef={wrapperRef}
                /> : null}
                <textarea
                    className="editInput"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    ref={editInputRef}
                    value={visibleValue}
                />
            </main>

            <footer className="editInput__buttons">
                <button
                    type="button"
                    className="editInput__btn btnSave"
                    disabled={disabled}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleUpdate()
                    }}
                >
                    Save
                </button>
                <button
                    type="button"
                    className="editInput__btn btnCancel"
                    onClick={(e) => {
                        e.stopPropagation()
                        closeEditBox()
                    }}
                >
                    Cancel
                </button>
            </footer>
        </>
    );
};

CommentEditBox.propTypes = {
    defaultValue: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.string
    })),
    defaultTags: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        commentUsername: PropTypes.string,
        id: PropTypes.number,
        range: PropTypes.arrayOf(PropTypes.number),
        fullText: PropTypes.string,
        index: PropTypes.number
    })),
    closeEditBox: PropTypes.func,
    handleEditEmit: PropTypes.func,
    contentRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    }),
    wrapperRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    })
}

export default CommentEditBox;
