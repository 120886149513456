/**
 * Parses properties from string or object.
 * @param {string|object} properties - Data to be parsed.
 * @returns {object} Parsed properties.
 */
export const parseDataToObject = (properties) => {
    if (!properties) return {}

    try {
        if (typeof properties === 'string') {
            return JSON.parse(properties);
        } else if (typeof properties === 'object') {
            return properties;
        }
    } catch (err) {
        console.error('Error happened parsing properties', err);
    }
    return {}
}

/**
 * Checks if the app is running in an iframe.
 * @returns {boolean} True if the app is running in an iframe.
 */
export const isAppRunningInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/**
 * Returns the shortened text based on the given width
 * Useful for rendering one line text.
 * @param {string} text - The original text.
 * @param {number} width - Width of the text box.
 * @param {CanvasRenderingContext2D} ctx - Canvas context -- make sure to set font and font size before calling this method.
 * @returns {string}
 */
export const getShortenedText = (text, width, ctx) => {
    // If the text fits in the given width, return it as is
    const originalTextWidth = ctx.measureText(text)?.width;
    if (originalTextWidth <= width) {
        return text;
    }

    let fullText = '';
    for (const letter of text) {
        const measureText = ctx.measureText(fullText + letter);
        if (measureText.width > width) {
            return fullText;
        } else if (ctx.measureText(fullText + letter + '...').width > width) {
            if (!fullText.length) return '';
            return fullText + '...';
        }
        fullText += letter;
    }
    return fullText;
}

/**
 * Handles opening text editor for natively rendered texts.
 * @param e
 */
export function dblClickHandlerForNativeTexts(e) {
    const target = e?.target;

    if (!target.text || !target.isTitleRendering) return;

    const pointer = target.canvas.getPointer(e);
    const aCoords = target.aCoords;

    if (pointer.x >= aCoords?.textTL?.x && pointer.x <= aCoords?.textTR?.x && pointer.y >= aCoords?.textTL?.y && pointer.y <= aCoords?.textBL?.y) {
        target.canvas.fire('open_text_editor', target);
    }
}

/**
 * Deep clones the given object.
 * @param {object} obj 
 * @returns 
 */
export const deepClone = (obj) => {
    let clone = Object.assign({}, obj);
    Object.keys(clone).forEach(
        key => (clone[key] = (obj[key] !== null && typeof obj[key] === 'object') ? deepClone(obj[key]) : obj[key])
    );
    return Array.isArray(obj) ? (clone.length = obj.length) && Array.from(clone) : clone;
};

/**
 * Clear empty objects and arrays if empty.
 * Deep Clean.
 * @param {object|Array} o 
 * @returns {object|Array}
 */
export function clearEmpties(o) {
    for (var k in o) {
        if (!o[k] || typeof o[k] !== 'object') {
            continue // If null or not an object, skip to the next iteration
        }

        // The property is an object
        clearEmpties(o[k]); // <-- Make a recursive call on the nested object
        if (Object.keys(o[k]).length === 0) {
            delete o[k]; // The object had no properties, so delete that property
        }
    }
    return o;
}

/**
 * Written to decode the Base64 encoding text.
 * @param {string} text Encoding text.
 * @returns {string} Returns decrypted text value.
 */
export const decodeTextFromBase64 = (text) => {
    return atob(text);
}

/**
 * Converts hex color to rgba.
 * @param {string} hex The hex color to convert rgba.
 * @returns {string} Rgba color of given hex.
 */
export function hexToRGBA(hex) {
    let r = 0, g = 0, b = 0, a = 1;
    try {
        if (hex.length === 4) { // #RGB
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 5) { // #RGBA
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
            a = parseInt(hex[4] + hex[4], 16) / 255;
        } else if (hex.length === 7) { // #RRGGBB
            r = parseInt(hex.slice(1, 3), 16);
            g = parseInt(hex.slice(3, 5), 16);
            b = parseInt(hex.slice(5, 7), 16);
        } else if (hex.length === 9) { // #RRGGBBAA
            r = parseInt(hex.slice(1, 3), 16);
            g = parseInt(hex.slice(3, 5), 16);
            b = parseInt(hex.slice(5, 7), 16);
            a = parseInt(hex.slice(7, 9), 16) / 255;
        }

    } catch (err) {
        console.error('Error while getting the rgba color', err);
    }

    return `rgba(${r}, ${g}, ${b}, ${a})`;
}