import { fabric } from 'fabric';
import {EDITING_METHODS} from '../Constant';

/**
 * We are creating new styleProperties variable without unused fabricjs default styleproperties due to increasing performance.
 * Removed properties are ['stroke', 'strokeWidth', 'fontFamily', 'fontSize', 'deltaY'] .
 */
export const _stylePropertiesForCompare = [
    'fill',
    'fontWeight',
    'fontStyle',
    'underline',
    'overline',
    'linethrough',
    'textBackgroundColor',
    'url'
];

/**
 * Sets fixedWidth as width of the text object in order to 
 * avoid misplacing of the frame because of the long text width.
 * @Override fabric.Text.prototype.initDimensions
 */
export function initDimensions() {
    if (this.__skipDimension) {
        return;
    }
    this._splitText();
    this._clearCache();
    if (this.path) {
        this.width = this.path.width;
        this.height = this.path.height;
    }
    else {
        let fixedWidth = null; 
        // if the text is a frame title, then we need to set fixedWidth
        if (this?.shapeType === 'frameText') {
            fixedWidth = this.fixedWidth;
        }

        // if we have fixedWidth, then we need to set it as width of the text object
        if (typeof fixedWidth === 'number') {
            this.width = fixedWidth;
        } else {
        // else just calculate width of the text as usual
            this.width = this.calcTextWidth() || this.cursorWidth || this.MIN_TEXT_WIDTH;
        }
        this.height = this.calcTextHeight();
    }
    if (this.textAlign.indexOf('justify') !== -1) {
        // once text is measured we need to make space fatter to make justified text.
        this.enlargeSpaces();
    }
    this.saveState({ propertySet: '_dimensionAffectingProps' });
}

/**
 *
 */
export function getAllStyles() {
    let characterCount = 0;
    const allStyles = {};

    for (const [idx, line] of this.__charBounds.entries()) {
        const currCharCount = characterCount;
        characterCount += line?.length - 1;
        const isNewLine = this.text[characterCount] === '\n' || this.text[characterCount] === ' ';

        for (let i = 0; i <= characterCount - currCharCount; i++) {
            const style = JSON.parse(JSON.stringify(this._getStyleDeclaration(idx, i) || {}));
            style.lineIndex = idx;
            style.charIndex = i;
            style.isNewLine = isNewLine && i === (characterCount - currCharCount);
            allStyles[currCharCount + i] = style;
        }

        if (isNewLine) characterCount += 1;
    }

    return { allStyles, characterCount };
}

export const getHyperlinksFromStyle = function() {
    // First collecting all styles included new lines property.
    const { allStyles, characterCount } = this.getAllStyles();  

    // Group them by their link value
    const links = [];
    const lines = {};
    let linkStart = { idx: -1, url: '' }

    for (const [idx, style] of Object.entries(allStyles)) {
        const index = parseInt(idx, 10);
        lines[index] = { lineIndex: style.lineIndex, charIndex: style.charIndex };

        if (style.isHyperlink && linkStart.idx === -1 && style.url && style.isNewLine !== true) {
            linkStart = { idx: index, url: style.url };
        }

        const isLinkChanged = style.url !== linkStart.url && style.isNewLine !== true;
        if (linkStart.idx !== -1 &&  (isLinkChanged || characterCount === index)) {
            links.push({
                startIndex: linkStart.idx,
                endIndex: index,
                url: linkStart.url
            });

            // Reset link state.
            if (style.isHyperlink) {
                linkStart = { idx: index, url: style.url }
            } else {
                linkStart = { idx: -1, url: '' }
            }
        }
    }
  

    return { links, lines }
}

/**
 * Getting hovered link selection indexes. We can change or remove that particular hyperlink by these indexes.
 * @param {string} hoveredLink 
 * @param lineIndex
 * @param start
 * @param end
 * @param canvas
 * @returns {{ selectionStart: number, selectionEnd: number }}
 */
export const getHoveredHyperlinkSelectionIndexes = function (lineIndex, start, end, canvas) {
    const styles = this.styles;
    if (!styles) return {};
    canvas = this.canvas || this.group?.canvas || canvas;
    this._renderTextCommon(canvas.getContext('2d'));

    let { links } = this.getHyperlinksFromStyle();

    // Finding the selection range in the line
    let characterCount = 0;
    let selectionStart = -1;
    let selectionEnd = -1;

    for (const [idx, line] of this.__charBounds.entries()) {
        if (idx < lineIndex) {
            characterCount += line?.length - 1;
            const isNewLine = this.text[characterCount] === '\n' || this.text[characterCount] === ' ';

            if (isNewLine) {
                characterCount += 1;
            }
            continue;
        }
    }

    selectionStart = characterCount + start;
    selectionEnd = characterCount + end + 1;

    // Finding the exact selection range.
    let activeLink = {};
    for (const link of links) {
        if (selectionStart >= link.startIndex && selectionEnd <= link.endIndex) {
            activeLink = link;
            break;
        }
    }

    return { selectionStart: activeLink.startIndex ?? -1, selectionEnd: activeLink.endIndex ?? -1 }
}

/**
 * We can remove hyperlink properties between indexes.
 * @param {number} startIndex 
 * @param {number} endIndex
 */
export const removeHyperLinkFromText = function (startIndex, endIndex) {
    if (typeof startIndex !== 'number' || typeof endIndex !== 'number') return;

    const hyperLinkProperties = ['underline', 'fill', 'isHyperlink', 'url'];

    for (let i = startIndex; i < endIndex; i++) {
        const loc = this.get2DCursorLocation(i);

        const styles = this._getStyleDeclaration(loc.lineIndex, loc.charIndex) || {};
        const newStyles = {};

        Object.entries(styles).forEach(([property, value]) => {
            if (!hyperLinkProperties.includes(property)) {
                newStyles[property] = value;
            }
        })

        this._setStyleDeclaration(loc.lineIndex, loc.charIndex, newStyles);
    }
}

/**
 * Copies selected text.
 * @override
 * @param {Event} e - Event object.
 */
export function onTextCopy() {
    if (this.selectionStart === this.selectionEnd) {
        //do not cut-copy if no selection
        return;
    }

    fabric.copiedText = this.getSelectedText();
    if (!fabric.disableStyleCopyPaste) {
        // fabric.copiedTextStyle = this.getSelectionStyles(this.selectionStart, this.selectionEnd, true);
        // @overrided content
        const copiedTextStyle = this.getSelectionStyles(this.selectionStart, this.selectionEnd);
        fabric.copiedTextStyle = copiedTextStyle;
    }
    else {
        fabric.copiedTextStyle = null;
    }
    this._copyDone = true;
}

/**
 * @override
 * Returns the text as an array of lines.
 * @param {String} text text to split
 * @returns {Array} Lines in the text
 * @deprecated I have overrided this function to increase the performance. But it cases some issues by detecting new text in onInput function. So disabled for now. 
 */
export function _splitTextIntoLines(text) {
    let lines = text.split(this._reNewline),
        newLines = new Array(lines.length),
        newLine = ['\n'],
        newText = [],
        startLineIndex = 0;

    if (Array.isArray(this._unwrappedTextLines) && this._unwrappedTextLines.length > 0) {
        const cursor = this.get2DCursorLocation(undefined, true);
        if (cursor.lineIndex > 0) {
            startLineIndex = cursor.lineIndex - 1;
  
            for (let i = 0; i < cursor.lineIndex; i++) {
                newLines[i] = this._unwrappedTextLines[i];
                newText = newText.concat(newLines[i], newLine);
            }
        }
    }

    for (let i = startLineIndex; i < lines.length; i++) {
        if (!newLines[i]) {
            newLines[i] = fabric.util.string.graphemeSplit(lines[i]);
            newText = newText.concat(newLines[i], newLine);
        }
    }
    newText.pop();
    return { _unwrappedLines: newLines, lines: lines, graphemeText: newText, graphemeLines: newLines };
}

/**
 * Handles onInput event.
 * @param {Event} e - Event object.
 */
export function onInput(e) {
    let fromPaste = this.fromPaste;
    this.fromPaste = false;
    e && e.stopPropagation();
    if (!this.isEditing) {
        return;
    }
    // decisions about style changes.
    let nextText = this._splitTextIntoLines(this.hiddenTextarea.value).graphemeText,
        charCount = this._text.length,
        nextCharCount = nextText.length,
        removedText, insertedText,
        charDiff = nextCharCount - charCount,
        selectionStart = this.selectionStart, selectionEnd = this.selectionEnd,
        selection = selectionStart !== selectionEnd,
        copiedStyle, removeFrom, removeTo;
    if (this.hiddenTextarea.value === '') {
        this.styles = {};
        this.updateFromTextArea();
        this.fire('changed', { selectionStart, selectionEnd });
        if (this.canvas) {
            this.canvas.fire('text:changed', { target: this });
            this.canvas.requestRenderAll();
        }
        return;
    }

    let textareaSelection = this.fromStringToGraphemeSelection(
        this.hiddenTextarea.selectionStart,
        this.hiddenTextarea.selectionEnd,
        this.hiddenTextarea.value
    );
    let backDelete = selectionStart > textareaSelection.selectionStart;

    if (selection) {
        removedText = this._text.slice(selectionStart, selectionEnd);
        charDiff += selectionEnd - selectionStart;
    }
    else if (nextCharCount < charCount) {
        if (backDelete) {
            removedText = this._text.slice(selectionEnd + charDiff, selectionEnd);
        }
        else {
            removedText = this._text.slice(selectionStart, selectionStart - charDiff);
        }
    }
    insertedText = nextText.slice(textareaSelection.selectionEnd - charDiff, textareaSelection.selectionEnd);
    if (removedText && removedText.length) {
        if (insertedText.length) {
            // let's copy some style before deleting.
            // we want to copy the style before the cursor OR the style at the cursor if selection
            // is bigger than 0.
            copiedStyle = this.getSelectionStyles(selectionStart, selectionStart + 1, false);
            // now duplicate the style one for each inserted text.
            copiedStyle = insertedText.map(function () {
                // this return an array of references, but that is fine since we are
                // copying the style later.
                return copiedStyle[0];
            });
        }
        if (selection) {
            removeFrom = selectionStart;
            removeTo = selectionEnd;
        }
        else if (backDelete) {
            // detect differences between forwardDelete and backDelete
            removeFrom = selectionEnd - removedText.length;
            removeTo = selectionEnd;
        }
        else {
            removeFrom = selectionEnd;
            removeTo = selectionEnd + removedText.length;
        }
        this.removeStyleFromTo(removeFrom, removeTo);
    }

    let isHyperlinkCopied = false;
    if (insertedText.length) {
        if (fromPaste && insertedText.join('') === fabric.copiedText && !fabric.disableStyleCopyPaste) {
            copiedStyle = fabric.copiedTextStyle;

            if (copiedStyle.length > 0) {
                const NOT_ALLOWED_STYLE_PROPS = ['fontSize', 'fontFamily'];

                copiedStyle = copiedStyle.map((p) => {
                    // We don't apply some style properties to table.
                    if (this.isTableTextbox) {
                        if (!p.url && !p.fontWeight && !p.fontStyle && !p.underline) return {};
                        const params = { fontWeight: p.fontWeight, fontStyle: p.fontStyle, underline: p.underline };

                        if (p.url) {
                            params.isHyperlink = true;
                            params.url = p.url;
                            params.fill = 'blue';
                            params.underline = true;

                            isHyperlinkCopied = true;
                        }

                        return params;
                    }

                    NOT_ALLOWED_STYLE_PROPS.forEach((prop) => delete p[prop]);

                    if (p.url) {
                        p.isHyperlink = true;
                        isHyperlinkCopied = true;
                    }

                    return p;
                });
            }
        }

        if (isHyperlinkCopied) {
            this.hasHyperlink = true;
        }

        this.insertNewStyleBlock(insertedText, selectionStart, copiedStyle);
    }
    this.updateFromTextArea();
    this.fire('changed', { insertedText, removedText, selectionStart, selectionEnd });
    if (this.canvas) {
        if (this.editingProcessId) {
            setTimeout(() => {
                this.canvas.collaborationManager.updateContinuousEditing(this.editingProcessId)
            },0)
        }
        this.canvas.fire('text:changed', { target: this });
        this.canvas.requestRenderAll();
    }
}

/**
 * Standard handler for mouse up, overridable.
 * @param options
 * @private
 */
export function mouseUpHandler(options) {
    this.__isMousedown = false;
    if (!this.editable || this.group ||
    (options.transform?.actionPerformed) ||
    (options.e.button && options.e.button !== 1)) {
        return;
    }

    if (this.canvas) {
        let currentActive = this.canvas._activeObject;
        // BUILDER.AI CHANGES: We are also checking the table uuid
        if (currentActive && currentActive !== this && currentActive.uuid !== this.tableUuid) {
            // avoid running this logic when there is an active object
            // this because is possible with shift click and fast clicks,
            // to rapidly deselect and reselect this object and trigger an enterEdit
            return;
        }
    }

    if (this.__lastSelected && !this.__corner) {
        this.selected = false;
        this.__lastSelected = false;
        
        if (!this.isEditing) {
            const { processId, aborted } = this.canvas.collaborationManager.startContinuousEditing(
                [this], 
                this.canvas.pageId,
                EDITING_METHODS.DIMENSION, // regular textbox' dimension is getting changed while typing
                EDITING_METHODS.TEXT,
                EDITING_METHODS.TEXT_FONT_STYLE,
                EDITING_METHODS.COLOR_OUTER,
            );
            if (aborted) {
                return
            }
            this.canvas.collaborationManager.setAbortionListener(processId, () => {
                this.canvas.discardActiveObject().requestRenderAll()
                this.editingProcessId = processId;
                this.editingStarted = false;
            })

            this.editingProcessId = processId;

            this.editingStarted = true;
        }
        
        this.enterEditing(options.e);
        if (this.selectionStart === this.selectionEnd) {
            this.initDelayedCursor(true);
        }
        else {
            this.renderCursorOrSelection();
        }
    }
    else {
        this.selected = true;
    }
}

/**
 * Default event handler for the basic functionalities needed on mousedown:before
 * can be overridden to do something different.
 * Scope of this implementation is: verify the object is already selected when mousing down.
 * @param options
 */
export function _mouseDownHandlerBefore(options) {
    if (!this.canvas || !this.editable || (options.e.button && options.e.button !== 1)) {
        return;
    }
    // we want to avoid that an object that was selected and then becomes unselectable,
    // may trigger editing mode in some way.
    this.selected = (this === this.canvas._activeObject) || (this.tableUuid && this.tableUuid === this.canvas._activeObject?.uuid);
}

/**
 * Returns true if object has no styling or no styling in a line.
 * @param {number} lineIndex - , lineIndex is on wrapped lines.
 * @returns {boolean}
 * @deprecated There are some issues in this function. Will check and improve later.
 */
export function isEmptyStyles(lineIndex) {
    if (!this.styles) {
        return true;
    }
    if (typeof lineIndex === 'undefined') {
        for (let key in this.styles) {
            return false;
        }

        return true;
    }

    let offset = 0, nextOffset,
        map = this._styleMap[lineIndex], mapNextLine = this._styleMap[lineIndex + 1];
    if (map) {
        lineIndex = map.line;
        offset = map.offset;
    }
    if (mapNextLine) {
        nextOffset = mapNextLine.offset ?? (offset + this._textLines[lineIndex].length);
    } else {
        nextOffset = offset + this._textLines[lineIndex].length;
    }

    const styles = this.styles[lineIndex];
    if (!styles) return true;

    for (let i = offset; i < nextOffset; i++) {
        if (styles[i]) {
            return false;
        }
    }

    return true;
}

/**
 * Return a new object that contains all the style property for a character
 * the object returned is newly created.
 * @override
 * @param {number} lineIndex - Of the line where the character is.
 * @param {number} charIndex - Position of the character on the line.
 * @returns {object} Style object.
 */
export function getCompleteStyleDeclaration(lineIndex, charIndex) {
    let style = this._getStyleDeclaration(lineIndex, charIndex) || { },
        styleObject = { ...style, fontSize: this.fontSize, fontFamily: this.fontFamily }, prop;

    for (let i = 0; i < this._stylePropertiesForCompare.length; i++) {
        prop = this._stylePropertiesForCompare[i];
        if (typeof style[prop] === 'undefined') {
            styleObject[prop] = this[prop];
        }
    }
    return styleObject;
}
