import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './DenyAccess.scss';

import CloseIcon from '../svgIcons/Close';
import Spinner from '../svgIcons/Spinner';
import { rejectRequestAccess } from '../../services/RequestAccessService';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import eventEmitter from '../../helpers/EventEmitter';
import { closeRequestAccessToast } from '../../helpers/ToastHelper';

const DenyAccess = ({
    deniedUserRef,
    isRequestEditDeny
}) => {
    const dispatch = useDispatch();
    const textareaRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [denyingRequest, setDenyingRequest] = useState(false);
    const [reasonMessage, setReasonMessage] = useState('');
    
    const {users} = useSelector(state => state?.board);

    const handleShowInviteModal = () => {
        closeRequestAccessToast();
        setTimeout(() => {
            dispatch({
                type: 'modal/toggleInvite',
                payload: {
                    shouldShow: true
                }
            })
        }, 100);
    }

    const {
        whiteBoardSlugId,
    } = useSelector((state) => state?.board);

    const user = deniedUserRef?.current;

    /**
     * @param {Event} event 
     * @returns 
     */
    const handleShowWarningBorder = (event) => {
        const value = event.target.value;

        if (value.trim().length > 200) {
            setReasonMessage(value.trim().substring(0, 200))
            return
        }
        if (event.type === 'change') setReasonMessage(value);

        const textareaWrapper = document.querySelector('.textarea-wrapper');
        const warningMessage = document.querySelector('.warning-message');

        if (value.trim().length < 20) {
            warningMessage?.classList.add('active');
            textareaWrapper?.classList.add('warning')
        }
        else {
            warningMessage?.classList.remove('active');
            textareaWrapper?.classList.remove('warning');
        }
    }

    const handleDenyAccess = async () => {
        setDenyingRequest(true);
        let isError = false;
        try {
            const response = await rejectRequestAccess(whiteBoardSlugId, { requestId: isRequestEditDeny ? user?.requestId : user?.id, message: reasonMessage.trim(), isUpgrade: isRequestEditDeny });
            if (response?.success) {
                setDenyingRequest(false);
                handleShowInviteModal();

                if(isRequestEditDeny){
                    dispatch({
                        type: 'board/changeBoardDetails',
                        payload: { users: [...users.map(item => {
                            return item.id === user.id ? {...item, isUpgrade: false} : item
                        })] }
                    })
                }
            }
        }
        catch (err) {
            isError = true;
            console.log(err)
            setDenyingRequest(false);
        }
        finally {
            if (isError) {
                setDenyingRequest(false);
            }
        }
    }

    useEffect(() => {
        setIsLoading(false);

        /**
         * @param {object} data 
         */
        const handleCancelDenyForInvitedUser = (data) => {
            if (data?.id === deniedUserRef?.current?.userId) handleShowInviteModal();
            else if (data?.status === 'reject' && !data?.requests.find(item => item.userId === deniedUserRef?.current?.userId)) handleShowInviteModal();
        }
        
        const handlePermissionUpdate = (data) => {
            if(deniedUserRef.current.id === data.userId && data.permission === 'edit') handleShowInviteModal();
        }

        eventEmitter.on('updateRequestList', handleCancelDenyForInvitedUser);
        if(isRequestEditDeny) eventEmitter.on('permissionUpdate', handlePermissionUpdate)
        return () => {
            eventEmitter.off('updateRequestList', handleCancelDenyForInvitedUser);
            if(isRequestEditDeny) eventEmitter.off('permissionUpdate', handlePermissionUpdate)
        }
    }, [])

    return (
        <section className={clsx('denyAccessModal', { loading: isLoading })}>
            {isLoading ? (
                <Spinner height={40} width={40} />
            ) : (
                <>
                    <header>
                        <h2>{isRequestEditDeny ? 'Deny request' : 'Please give a reason'}</h2>

                        <button type="button" data-testid="closeBtn" onClick={handleShowInviteModal}>
                            <CloseIcon height={18} width={18} />
                        </button>
                    </header>
                    <main>
                        <span className='denyAccessModal--denied-info'><span>Let</span> <span className='denied-mail'>{deniedUserRef.current.email}</span> know {isRequestEditDeny ? 'the reason to deny' : 'why you are denying their'} request</span>   
                        <fieldset className="denyAccessModal--form">
                            <div onClick={() => textareaRef?.current?.focus()} className="textarea-wrapper">
                                <textarea ref={textareaRef} value={reasonMessage} onChange={handleShowWarningBorder} onBlur={handleShowWarningBorder} placeholder='Add message' />
                                <span className="length-indicator"> {reasonMessage.trim().length} / 200 </span>
                            </div>
                            <span className='warning-message'>Minimum 20 characters</span>
                            <button
                                className="cancel-deny-access-button"
                                data-testid="cancel-deny-access-button"
                                onClick={handleShowInviteModal}
                                type="submit"
                            >
                                Cancel
                            </button>
                            <div className={clsx('deny-access-button-wrapper', {disabled: reasonMessage.trim().length < 20})}>
                                <button
                                    className={clsx('deny-access-button', {'request-edit-deny': isRequestEditDeny})}
                                    data-testid="deny-access-button"
                                    disabled={denyingRequest || reasonMessage.trim().length < 20}
                                    onClick={handleDenyAccess}
                                    type="submit"
                                >
                                    <ClipLoader color="#fff" loading={denyingRequest} size={22} /> {denyingRequest ? '' : 'Deny'}
                                </button>
                                {
                                    reasonMessage.length < 20 ? <span className='warning-tooltip'>
                                        Please provide a reason for denying
                                    </span> : null
                                }
                            </div>
                        </fieldset>
                    </main>
                </>
            )}
        </section>
    );
}

DenyAccess.propTypes = {
    deniedUserRef: PropTypes.shape({
        current: PropTypes.object
    }),
    isRequestEditDeny: PropTypes.bool
}

export default DenyAccess;