// WARNING: this file includes some old methods for our old implementation for the lines.
// We are not using those methods anymore, but we keep them here just in case we need them later.
import { fabric } from 'fabric';
import {EDITING_METHODS, LINE_ATTACHABLE_OBJECT_TYPES, modes} from '../helpers/Constant';
import { removeCanvasListener, stopDrawing } from '../helpers/CommonFunctions'
import {changeObjectsSelectableProp} from '../helpers/FabricMethods';
import { getLocalStorage, setLocalStorage } from '../services/CookieService';
import { customContainPointsWidthPadding } from '../helpers/lines/LineMethods';
import { makeCustomFlowchartHotspot } from '../helpers/flowchart/CustomFlowchartShapeUtils';
import {rotatePointBack} from '../helpers/lines/AttachedObjectTransformHandlers';

let drawInstance = null,
    mouseDown = false,
    options;

export const LineArrow = fabric.util.createClass(fabric.Line, {
    type: 'LineArrow',

    initialize(element, options) {
        options || (options = {});
        this.callSuper('initialize', element, options);
    },

    _render(ctx) {
        this.callSuper('_render', ctx);

        // do not render if width/height are zeros or object is not visible
        if (this.width === 0 || this.height === 0 || !this.visible) return;
        ctx.save();

        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        const angle = Math.atan2(yDiff, xDiff);
        ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
        ctx.rotate(angle);
        ctx.beginPath();
        // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
        ctx.moveTo(5, 0);
        ctx.lineTo(-5, 5);
        ctx.lineTo(-5, -5);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
    }
});

export const lineArrowFromObject = function (object, callback) {
    /**
     * @param instance
     */
    function _callback(instance) {
        delete instance.points;
        callback && callback(instance);
    }
    let options = JSON.parse(JSON.stringify(object));
    options.points = [object.x1, object.y1, object.x2, object.y2];
    fabric.Object._fromObject('LineArrow', options, _callback, 'points');
};

export const createLine = (canvas, emitOnMouseDown, onMouseDownLineHandler, isArrow) => {
    if (getLocalStorage('bboard_options')) {
        options = JSON.parse(getLocalStorage('bboard_options'));
    }
    options.currentMode = modes.LINE;
    setLocalStorage('bboard_options', JSON.stringify(options));
    removeCanvasListener(canvas);
    canvas.on('mouse:down', startAddLine(canvas, isArrow));
    canvas.on('mouse:move', startDrawingLine(canvas));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
};

const startAddLine = (canvas, isArrow) => {
    return _ref => {
        let {
            e
        } = _ref;
        mouseDown = true;
        let pointer = canvas.getPointer(e);
        if (isArrow) {
            drawInstance = new LineArrow([pointer.x, pointer.y, pointer.x, pointer.y], {
                strokeWidth: options.currentWidth,
                stroke: options.currentColor,
                selectable: true,
                hasBorders: false,
                hasControls: true,
                perPixelTargetFind: true
            });
        }
        else {
            drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                strokeWidth: options.currentWidth,
                stroke: options.currentColor,
                selectable: true,
                hasBorders: false,
                hasControls: true,
                perPixelTargetFind: true
            });
        }
        // setFabricObjectContorlsVisibility(drawInstance, false);
        canvas.add(drawInstance);
        canvas.requestRenderAll();
    };
};

const startDrawingLine = (canvas) => {
    return _ref2 => {
        let {
            e
        } = _ref2;

        if (mouseDown) {
            const pointer = canvas.getPointer(e);
            drawInstance.set({
                x2: pointer.x,
                y2: pointer.y
            });
            drawInstance.setCoords();
            canvas.requestRenderAll();
        }
    };
};

export const createPoint = (pointType) => {
    return new fabric.Circle({
        left: 0,
        top: 0,
        radius: 6,
        visible: false,
        selectable: false,
        stroke: 'blue',
        fill: '',
        originX: 'center',
        originY: 'center',
        hasControls: false,
        hasBorders: false,
        pointType: pointType
    });
};

const setCirclePoint = (target, startPoint, endPoint) => {
    startPoint.set({
        left: target.x1,
        top: target.y1,
    }).setCoords();
    endPoint.set({
        left: target.x2,
        top: target.y2,
    }).setCoords();
};

export const selectLine = (canvas, target, startPoint, endPoint, selectedLine) => {
    setCirclePoint(target, startPoint.current, endPoint.current);
    startPoint.current.visible = true;
    startPoint.current.selectable = true;
    endPoint.current.visible = true;
    endPoint.current.selectable = true;
    selectedLine.current = target;
    startPoint.current.bringToFront();
    endPoint.current.bringToFront();
    canvas.requestRenderAll();
};

export const deSelectLine = (startPoint, endPoint, selectedLine) => {
    startPoint.current.visible = false;
    endPoint.current.visible = false;
    selectedLine.current = false;
};

export const customContainPoints = (shape, point) => {
    const shapeCoordinates = shape.getBoundingRect(true, true);
    const objectPos = {
        xStart: shapeCoordinates.left,
        xEnd: shapeCoordinates.left + shapeCoordinates.width,
        yStart: shapeCoordinates.top,
        yEnd: shapeCoordinates.top + shapeCoordinates.height
    }

    if (point.x >= objectPos.xStart && point.x <= (objectPos.xEnd)) {
        if (point.y >= objectPos.yStart && point.y <= objectPos.yEnd) return true;
    }

    return false;
}

/**
 * Attaches the line to the nearest polygon.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.CurvedLine} line 
 * @param {*} socketRef 
 * @param {number} whiteBoardId 
 * @param {number} userId 
 * @param {object} options 
 * @param {boolean} options.dontEmitAttachedLine - If true, the line will not be emitted with shapeModified which is unnecessary when the line is just drawn.
 * @param {boolean} options.forceEditingOldPolygons Checks old attached polygons and forces adding the line to the lines array.
 */
export const connectToGroup = (canvas, line, socketRef, whiteBoardId, userId, options = {}) => {
    let leftPolygons = [], rightPolygons = [];
    let isDeltaChanged = false;
    const lineHeadPoints = line.getHeadPoints();
    
    const oldLeftPolygonUuid = line?.leftPolygon?.uuid;
    const oldRightPolygonUuid = line?.rightPolygon?.uuid;

    const lineX1 = parseFloat(lineHeadPoints[0].x?.toFixed(2));
    const lineY1 = parseFloat(lineHeadPoints[0].y?.toFixed(2));
    const lineX2 = parseFloat(lineHeadPoints[1].x?.toFixed(2));
    const lineY2 = parseFloat(lineHeadPoints[1].y?.toFixed(2));

    canvas.getObjects().forEach((element, index) => {
        if (element.collabLocked) {
            return
        }
        if (element.uuid && (LINE_ATTACHABLE_OBJECT_TYPES.includes(element.type))) {
            if (customContainPointsWidthPadding(element, { x: lineX1, y: lineY1 }, 5)) leftPolygons.push(index);
            if (customContainPointsWidthPadding(element, { x: lineX2, y: lineY2 }, 5)) rightPolygons.push(index);
            
        }
    });

    if (leftPolygons.length) {
        const leftPolygon = canvas.getObjects()[Math.max(...leftPolygons)];
        if (
            (leftPolygon?.uuid !== oldLeftPolygonUuid) ||
            options?.forceEditingOldPolygons
        ) {
            if (options?.isContinuous && options?.processId) {
                canvas.collaborationManager.addLaterShapes(
                    [leftPolygon],
                    options.processId,
                    EDITING_METHODS.ATTACHMENT_LINE
                )
            } else if (options?.isScenarioEditing && options.processId) {
                canvas.collaborationManager.modifiedInScenario(
                    leftPolygon,
                    options.processId,
                    EDITING_METHODS.ATTACHMENT_LINE
                )
            }

            if (!leftPolygon.lines) leftPolygon.lines = [];
            let lineIndex = leftPolygon.lines?.indexOf(line.uuid);
            if (lineIndex === -1 && options.dontAddLines !== true) leftPolygon.lines.push(line.uuid);
        }

        // calculate attached posiitons according to the origin based position of the polygon
        const centerPoint = leftPolygon.getCenterPoint();

        const rotated = rotatePointBack(
            lineX1,
            lineY1,
            centerPoint.x,
            centerPoint.y,
            leftPolygon.angle
        )
        const ratio = line.calculateRelativeRatio(rotated.x, rotated.y, centerPoint, leftPolygon);
        line.leftRelativeX = ratio.x;
        line.leftRelativeY = ratio.y;

        isDeltaChanged = true;

        if (options.isContinuous && options.processId) {
            const editingMap = new Map()
            const editingMethod = new Set();
            editingMethod.add(EDITING_METHODS.LINE_POLYGON_LEFT)
            editingMap.set(line, editingMethod)
            canvas.collaborationManager.addIsolatedEditingMethod(
                options.processId,
                editingMap
            )
        }

        line.leftPolygon = leftPolygon;

        if (leftPolygon.flowchartProps) {
            makeCustomFlowchartHotspot(line, 'left');
        }
    }
    if (rightPolygons.length) {
        const rightPolygon = canvas.getObjects()[Math.max(...rightPolygons)];
        if (
            rightPolygon?.uuid !== oldRightPolygonUuid ||
            options?.forceEditingOldPolygons
        ) {
            if (options?.isContinuous && options?.processId) {
                canvas.collaborationManager.addLaterShapes(
                    [rightPolygon],
                    options.processId,
                    EDITING_METHODS.ATTACHMENT_LINE
                )
            } else if (options?.isScenarioEditing && options.processId) {
                canvas.collaborationManager.modifiedInScenario(
                    rightPolygon,
                    options.processId,
                    EDITING_METHODS.ATTACHMENT_LINE
                )
            }

            if (!rightPolygon.lines) rightPolygon.lines = [];
            let lineIndex = rightPolygon.lines?.indexOf(line.uuid);
            if (lineIndex === -1 && options.dontAddLines !== true) rightPolygon.lines.push(line.uuid);
        }

        // calculate attached posiitons according to the origin based position of the polygon
        const centerPoint = rightPolygon.getCenterPoint();
        const rotated = rotatePointBack(
            lineX2,
            lineY2,
            centerPoint.x,
            centerPoint.y,
            rightPolygon.angle
        )

        const ratio = line.calculateRelativeRatio(rotated.x, rotated.y, centerPoint, rightPolygon);
        line.rightRelativeX = ratio.x;
        line.rightRelativeY = ratio.y;

        isDeltaChanged = true;

        if (options.isContinuous && options.processId) {
            const editingMap = new Map()
            const editingMethod = new Set();
            editingMethod.add(EDITING_METHODS.LINE_POLYGON_RIGHT)
            editingMap.set(line, editingMethod)
            canvas.collaborationManager.addIsolatedEditingMethod(
                options.processId,
                editingMap
            )
        }

        line.rightPolygon = rightPolygon;

        if (rightPolygon.flowchartProps) {
            makeCustomFlowchartHotspot(line, 'right');
        }
    }

    if (options?.isContinuous && options?.processId) {
        if (oldLeftPolygonUuid && oldLeftPolygonUuid !== line.leftPolygon?.uuid) {
            const objectInCanvas = canvas.getObjects().find(o => o.uuid === oldLeftPolygonUuid)
            if (objectInCanvas) {
                if (!objectInCanvas.lines) {
                    objectInCanvas.lines = []
                } else if (Array.isArray(objectInCanvas.lines)) {
                    objectInCanvas.lines = objectInCanvas.lines.filter(lineUuid => lineUuid !== line.uuid)
                }
                canvas.collaborationManager.addLaterShapes(
                    [objectInCanvas],
                    options.processId,
                    EDITING_METHODS.ATTACHMENT_LINE
                ) 
            }
        }
        if (oldRightPolygonUuid && oldRightPolygonUuid !== line.rightPolygon?.uuid) {
            const objectInCanvas = canvas.getObjects().find(o => o.uuid === oldRightPolygonUuid)
            if (objectInCanvas) {
                if (!objectInCanvas.lines) {
                    objectInCanvas.lines = []
                } else if (Array.isArray(objectInCanvas.lines)) {
                    objectInCanvas.lines = objectInCanvas.lines.filter(lineUuid => lineUuid !== line.uuid)
                }
                canvas.collaborationManager.addLaterShapes(
                    [objectInCanvas],
                    options.processId,
                    EDITING_METHODS.ATTACHMENT_LINE
                )
            }
        }
    }

    // We are changing arrow direction in some scenarios in case of delta is changed. So need to re-render.
    if (isDeltaChanged) {
        line.onShapeChanged();
    }

    return { isDeltaChanged }
};


export const checkIfLineIsConnectWithShape = (line, element) => {
    const lineHeadPoints = line.getHeadPoints();

    const lineX1 = lineHeadPoints[0].x;
    const lineY1 = lineHeadPoints[0].y;
    const lineX2 = lineHeadPoints[1].x;
    const lineY2 = lineHeadPoints[1].y;
    return customContainPointsWidthPadding(element, { x: lineX2, y: lineY2 }, 5) || customContainPointsWidthPadding(element, { x: lineX1, y: lineY1 }, 5);
}

export const calcLineCoords = (line) => {
    const {
        tl, tr, bl, br,
    } = line.aCoords;
    let coordsStart;
    let coordsEnd;
  
    if (line.x1 > line.x2) {
        if (line.y1 > line.y2) {
            coordsStart = br;
            coordsEnd = tl;
        } else {
            coordsStart = tr;
            coordsEnd = bl;
        }
    } else {
        if (line.y1 > line.y2) {
            coordsStart = bl;
            coordsEnd = tr;
        } else {
            coordsStart = tl;
            coordsEnd = br;
        }
    }
  
    return [coordsStart, coordsEnd];
}
