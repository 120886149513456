import { fabric } from 'fabric';
import magnetEffectForPolygon from './MagnetEffect';
import { lineAlignmentHandler } from '../alignment/GenerateAlignmentLines';
import { clearAlignmentLines } from '../alignment/Utils';

/**
 * Changes the position of the control points of the line.
 * @param dim
 * @param finalMatrix
 * @param fabricObject
 */
export function linePointPosiitonHandler(dim, finalMatrix, fabricObject) {
    // if the object or the canvas isn't defined, return 0
    if (!fabricObject || !fabricObject.canvas) {
        return {
            x: 0,
            y: 0
        }
    }

    const points = this.isHypoPoint ? fabricObject.hypoPoints : fabricObject.points;
    const actualIndex = this.isHypoPoint ? this.hypoPointIndex : this.pointIndex;

    if (!points[actualIndex]) {
        return {
            x: 0,
            y: 0
        }
    }

    const x = (points[actualIndex].x - fabricObject.pathOffset.x),
        y = (points[actualIndex].y - fabricObject.pathOffset.y);

    return fabric.util.transformPoint(
        { x: x, y: y },
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
        )
    );
}

/**
 * @param object
 */
function getObjectSizeWithStroke(object) {
    const stroke = new fabric.Point(
        object.strokeUniform ? 1 / object.scaleX : 1, 
        object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

/**
 * @param anchorIndex
 * @param fn
 */
export function lineAnchorWrapper(anchorIndex, fn) {
    return function(eventData, transform, x, y) {
        convertHypoToActualPoint(transform); // Convert hypo point to actual point if its hypo point.
        const fabricObject = transform.target;
        const lastControl = fabricObject.points.length - 1;
        const currentControl = fabricObject.controls[transform.corner];
        const points = fabricObject.points;
        const index = currentControl.pointIndex > 0 ? currentControl.pointIndex - 1 : lastControl;

        const absolutePoint = fabric.util.transformPoint({
            x: (points[index].x - fabricObject.pathOffset.x),
            y: (points[index].y - fabricObject.pathOffset.y),
        }, fabricObject.calcTransformMatrix());
        const actionPerformed = fn(eventData, transform, x, y);

        fabricObject._setPositionDimensions({});
        const polygonBaseSize = getObjectSizeWithStroke(fabricObject),
            newX = (points[index].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
            newY = (points[index].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
        fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

        // Hide the hypo point control connectors during action.
        if (actionPerformed) {
            Object.values(fabricObject.controls).filter(c => c.isHypoPoint).forEach(c => {
                c.setVisibility(false)
            });

            fabricObject.movingPointIndex = currentControl.pointIndex;
            fabricObject.convertCurvedLineVersionToV2();
        }

        return actionPerformed;
    }
}


/**
 * @param eventData
 * @param transform
 * @param x
 * @param y
 */
export function lineActionHandler(eventData, transform, x, y) {
    const polygon = transform.target,
        currentControl = polygon.controls[transform.corner],
        pointIndex = currentControl?.pointIndex,
        mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
        polygonBaseSize = getObjectSizeWithStroke(polygon),
        size = polygon._getTransformedDimensions(0, 0),
        finalPointPosition = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
        };
    
    lineAlignmentHandler(polygon, pointIndex, finalPointPosition);
    polygon.points[pointIndex] = finalPointPosition;
    magnetEffectForPolygon(polygon, pointIndex);
    return true;
}

/**
 * Connector - Mouse up action handler.
 * @param {fabric.Object} object 
 */
export function lineControlMouseUpHandler(object) {
    return function (eventData, transform) {
        const target = transform?.target || object;

        if (target && target.canvas) {
            target.mouseUpHandledWithControl = true;
            setTimeout(() => {
                target.organizeControls();
                delete target.movingPointIndex;
                target.canvas.renderAll();
            }, 0);
            // remove the alignment lines if there is any
            clearAlignmentLines(target.canvas);
        }
    }
}


/**
 * @param line
 * @param pointPosition
 * @param newPosition
 * @param options
 */
export function changeLineHeadPos(line, pointPosition, newPosition, options = {}) {
    if (pointPosition === 'start') {
        line.points[0] = newPosition;
    } else if (pointPosition === 'end') {
        line.points[line.points.length - 1] = newPosition;
    } else if (pointPosition === 'middle') {
        line.points[options?.pointIndex || 1] = newPosition;
    }
    line.onShapeChanged()

    // set dimensions and fire the changed event
    line._setPositionDimensions({shouldFireChanged: true});
    
    // if line is not in a active selection group, set the coords
    if (!line.group) {
        line.setCoords();
    } else {
        // otherwise, we need to calculate the left and top position of the line
        // since the line is in a group
        const groupCenter = line.group.getCenterPoint();
        line.group._updateObjectCoords(line, groupCenter)
        line.group.addWithUpdate()
    }
}

/**
 * Converting hypo points to actual point.
 * @param {fabric.Transform} transform 
 */
export function convertHypoToActualPoint(transform) {
    const target = transform?.target;

    if (target && target.canvas) {
        const currentControl = target.controls[transform.corner];
        if (currentControl?.isHypoPoint && Array.isArray(target.hypoPoints)) {
            const hypoPoint = target.hypoPoints[currentControl.hypoPointIndex];

            if (hypoPoint) {
                const { x, y } = hypoPoint;
                currentControl.isHypoPoint = false;
                target.points.splice(currentControl.actualIndex, 0, { x, y });
                target.hypoPoints.splice(currentControl.hypoPointIndex, 1);
                transform.corner = `p${currentControl.actualIndex}`; // We need to update current control. Otherise, arrow position will be disturbed on mousemove.
                target.setControls();
            }
        }
    }
}

/**
 * 
 * @param {CanvasRenderingContext2D} ctx 
 * @param {number} left 
 * @param {number} top 
 * @param {object} styleOverride 
 * @param {fabric.Object} fabricObject 
 */
export function renderControl(ctx, left, top, styleOverride, fabricObject) {
    if (this.isHypoPoint) {
        styleOverride.cornerColor = '#fff';
        styleOverride.cornerStrokeColor = '#536DFE';
    } else {
        styleOverride.cornerColor = 'rgba(0,0,255,0.5)';
    }

    if (this.isHypoPoint && fabricObject.isMoving) {
        return
    }
    fabric.controlsUtils.renderCircleControl.call(this, ctx, left, top, styleOverride, fabricObject);
}