import { APP_NAMES, DEFAULT_SHARED_PERMISSIONS, LINE_TYPES, SHAPE_DEFAULTS } from '../../../helpers/Constant';

const currentURLNodes = window?.location?.pathname?.split('/');
const isPublicLink = (Array.isArray(currentURLNodes) && currentURLNodes.length > 2) ? currentURLNodes[2].length > 16 : false;

export const initialBoardState = {
    currentWidth: 2,
    boardName: '',
    whiteBoardSlugId: null,
    whiteBoardId: null,
    currentColor: SHAPE_DEFAULTS.STROKE,
    currentMode: 'select',
    hideComments: false,
    hideResolvedComments: true,
    studioBuildCardID: null,
    flowchartData: {
        status: 'pending',
    },
    action: {
        selectMode: true
    },
    migrationData: {},
    publicShareKey: null,
    publicSharePermission: DEFAULT_SHARED_PERMISSIONS,
    users: [],
    whiteBoardOwnerId: null,
    requestList: [],
    defaultLineType: LINE_TYPES.STRAIGHT,
    isPublicAccess: isPublicLink, // Might change in later
    isPublicLink,
    appName: APP_NAMES.APEIROS,
    recentSelectedColors: [SHAPE_DEFAULTS.TEXT_COLOR, SHAPE_DEFAULTS.STICKY_NOTE_BACKGROUND, SHAPE_DEFAULTS.FRAME_BACKGROUND]
}