import {fabric} from 'fabric';
import {getEditingShapes} from '../../FabricMethods';
import {EDITING_METHODS} from '../../Constant';
import detachControl from '../../lines/DetachControl';
import {setLinePointsForCurrentPosition} from '../../lines/LineMethods';

/**
 * Method that defines the actions when mouse is hovering the canvas.
 * The currentTransform parameter will define whether the user is rotating/scaling/translating
 * an image or neither of them (only hovering). A group selection is also possible and would cancel
 * all any other type of action.
 * In case of an image transformation only the top canvas will be rendered.
 * @override
 * @param {Event} e Event object fired on mousemove.
 */
export function __onMouseMove(e) {
    this._handleEvent(e, 'move:before');
    this._cacheTransformEventData(e);
    var target, pointer;

    if (this.isDrawingMode) {
        this._onMouseMoveInDrawingMode(e);
        return;
    }

    if (!this._isMainEvent(e)) {
        return;
    }

    var groupSelector = this._groupSelector;

    // We initially clicked in an empty area, so we draw a box for multiple selection
    if (groupSelector) {
        pointer = this._absolutePointer;

        groupSelector.left = pointer.x - groupSelector.ex;
        groupSelector.top = pointer.y - groupSelector.ey;

        this.renderTop();
    }
    else if (!this._currentTransform) {
        target = this.findTarget(e) || null;
        this._setCursorFromEvent(e, target);
        this._fireOverOutEvents(target, e);
    }
    else {
        // override
        const { target } = this._currentTransform;
        if (target) {
            target.onTransformChanged();
        }
        this._transformObject(e);
    }
    this._handleEvent(e, 'move');
    this._resetTransformEventData();
}

export function _performTransformAction(e, transform, pointer) {
    var x = pointer.x,
        y = pointer.y,
        action = transform.action,
        actionPerformed = false,
        actionHandler = transform.actionHandler;
    
    // if target is locked, do not allow transformation
    if (transform?.target?.isLocked || transform?.target?.lockMovementX || transform?.target?.lockMovementY) {
        return
    }

    // if the current point has a polygon and that polygon is being edited, do not allow modifying the point
    if (action === 'modifypolygon' && transform?.target?.type === 'curvedLine' && !transform.checkedPolygonLock) {
        try {
            const currentControl = transform.target.controls[transform.corner];
            const pointIndex = currentControl.pointIndex;
            if (pointIndex === 0 || pointIndex === transform.target.points.length - 1) {
                // if the moving point is a head point, it is possible it to have an attached polygon
                // so the attached polygon is locked by other users, we should not allow user to take drag action for this point
                const side = pointIndex === 0 ? 'left' : 'right';
                const polygonSide = transform.target[`${side}Polygon`]
                if (polygonSide) {
                    const polygonSideInCanvas = this.getObjects().find(o => o.uuid === polygonSide?.uuid)
                    if (polygonSideInCanvas && polygonSideInCanvas.collabLocked) {
                        return false;
                    }
                }
            }
        } catch (err) {
            console.error('error while checking polygon object', err)
        }

        transform.checkedPolygonLock = true;
    }
    
    const removedLinesDuringEditing = []
    
    // this object could be created from the function in the control handlers
    if (actionHandler) {
        if (!transform.aborted) {
            const target = transform?.target;
            if (
                action === 'drag' &&
                target
            ) {
                // if the target is a line, and it has a polygon attached, 
                // then we should not allow the user to drag the line
                if (target?.type === 'curvedLine' && (target.leftPolygon || target.rightPolygon)) {
                    this.setCursor(this.defaultCursor);
                    return;
                } else if (target?.type === 'activeSelection' && target.getObjects().every(obj => obj?.type === 'curvedLine' && (obj.leftPolygon || obj.rightPolygon))) {
                    // if the target is an active selection, and all the objects are lines with polygons attached, 
                    // then we should not allow the user to drag the lines
                    this.setCursor(this.defaultCursor);
                    return;
                }
            } else if (
                action === 'rotate' &&
                target?.type === 'activeSelection' && target.getObjects().every(obj => obj?.type === 'curvedLine' && (obj.leftPolygon || obj.rightPolygon))
            ) {
                // if the target is an active selection, and all the objects are lines with polygons attached, 
                // then we should not allow the user to rotate the lines
                this.setCursor(this.defaultCursor);
                return;
            }
            
            if (
                target?.type === 'activeSelection' &&
                !transform.handledDeletingAttachedLines &&
                (action === 'rotate' || action === 'drag' || action === 'scale')
            ) {
                transform.handledDeletingAttachedLines = true;
                target.getObjects().forEach(obj => {
                    if (obj.type !== 'curvedLine') {
                        return
                    }
                    if (!(obj.leftPolygon || obj.rightPolygon) && action === 'drag') {
                        return
                    }
                    
                    target.remove(obj)
                    fabric.util.addTransformToObject(obj, target.calcOwnMatrix());
                    obj.setCoords()
                    delete obj.group;
                    removedLinesDuringEditing.push(obj)
                    
                    let key;
                    if (action === 'rotate') key = 'removedLinesDuringRotating';
                    if (action === 'drag') key = 'removedLinesDuringMoving';
                    if (action === 'scale') key = 'removedLinesDuringScaling';
                    
                    if (!target[key]) {
                        target[key] = []
                    }
                    target[key].push(obj)
                })
            }
            
            let shouldLock = true;

            // comments should not be locked
            if (target.type === 'comment') {
                shouldLock = false;
            }
            
            // target shouldn't bed locked if the connector is getting drawn
            if (action === 'connector') {
                shouldLock = false;
            }
            
            if (shouldLock && !transform.target.isSentEditing) {
                const editingMethods = new Set();
                if (action === 'drag' || action === 'expanse') {
                    editingMethods.add(EDITING_METHODS.MOVE)
                    if (target.type === 'curvedLine') {
                        editingMethods.add(EDITING_METHODS.LINE_POINT_UPDATE)
                    }
                } else if (action === 'scale' || action === 'resizing') {
                    // it is important to add move here since scaling and resizing might change the
                    // position of the object.
                    editingMethods.add(EDITING_METHODS.MOVE)
                    editingMethods.add(EDITING_METHODS.DIMENSION)
                } else if (action === 'rotate') {
                    editingMethods.add(EDITING_METHODS.ROTATE)
                    editingMethods.add(EDITING_METHODS.MOVE)
                } else if (action === 'modifypolygon') {
                    editingMethods.add(EDITING_METHODS.MOVE)
                    editingMethods.add(EDITING_METHODS.DIMENSION)
                    editingMethods.add(EDITING_METHODS.LINE_POINT_UPDATE)
                }
                const allEditingShapes = getEditingShapes(this, transform.target)
                // if any line is removed due to attaching, do not edit them
                const editingShapes = allEditingShapes.filter(shape => !removedLinesDuringEditing.includes(shape))
                
                const editingMethodsMap = new Map();
                
                if (transform.target.type === 'frame' && action === 'scale') {
                    for (const shape of editingShapes) {
                        if (shape === transform.target) {
                            editingMethodsMap.set(shape, editingMethods)
                        } else {
                            editingMethodsMap.set(shape, new Set([EDITING_METHODS.ATTACHMENT_FRAME]))
                        }
                    } 
                } else {
                    editingShapes.forEach(shape => editingMethodsMap.set(shape, editingMethods))
                }
                
                const { processId, aborted } = this.collaborationManager.startIsolatedContinuousEditing(
                    editingShapes,
                    this.pageId,
                    editingMethodsMap
                )
                this.collaborationManager.setAbortionListener(processId, () => {
                    transform.aborted = true;
                    this.__onMouseUp(e)
                })
                transform.processId = processId;
                transform.target.isSentEditing = true;
                transform.target.canvasEventsProcessId = processId;
                transform.editingMethods = editingMethods
                transform.isContinuous = true;
                
                transform.aborted = aborted === true
            }
            if (!shouldLock) {
                actionPerformed = actionHandler(e, transform, x, y);
            } else if (!transform.aborted) {
                const processId = transform.processId;
                actionPerformed = actionHandler(e, transform, x, y);
                this.collaborationManager.updateContinuousEditing(processId)
            }
        }
    }
    if (action === 'drag' && actionPerformed && !transform.aborted) {
        transform.target.isMoving = true;
        this.setCursor(transform.target.moveCursor || this.moveCursor);
        if (transform.target.type === 'curvedLine') {
            setLinePointsForCurrentPosition(transform.target);
        }
    }
    transform.actionPerformed = transform.actionPerformed || actionPerformed;
}


function checkClick(e, value) {
    return e.button && (e.button === value - 1);
}

const RIGHT_CLICK = 3, MIDDLE_CLICK = 2, LEFT_CLICK = 1;

export function __onMouseUp (e) {
    var target, transform = this._currentTransform,
        groupSelector = this._groupSelector, shouldRender = false,
        isClick = (!groupSelector || (groupSelector.left === 0 && groupSelector.top === 0));
    this._cacheTransformEventData(e);
    target = this._target;
    this._handleEvent(e, 'up:before');
    // if right/middle click just fire events and return
    // target undefined will make the _handleEvent search the target
    if (checkClick(e, RIGHT_CLICK)) {
        if (this.fireRightClick) {
            this._handleEvent(e, 'up', RIGHT_CLICK, isClick);
        }
        return;
    }

    if (checkClick(e, MIDDLE_CLICK)) {
        if (this.fireMiddleClick) {
            this._handleEvent(e, 'up', MIDDLE_CLICK, isClick);
        }
        this._resetTransformEventData();
        return;
    }

    if (this.isDrawingMode && this._isCurrentlyDrawing) {
        this._onMouseUpInDrawingMode(e);
        return;
    }

    if (!this._isMainEvent(e)) {
        return;
    }
    if (transform) {
        this._finalizeCurrentTransform(e);
        shouldRender = transform.actionPerformed;
    }
    if (!isClick) {
        var targetWasActive = target === this._activeObject;
        this._maybeGroupObjects(e);
        if (!shouldRender) {
            shouldRender = (
                this._shouldRender(target) ||
                (!targetWasActive && target === this._activeObject)
            );
        }
    }
    var corner, pointer;
    if (target) {
        corner = target._findTargetCorner(
            this.getPointer(e, true),
            fabric.util.isTouchEvent(e)
        );
        if (target.selectable && target !== this._activeObject && target.activeOn === 'up') {
            this.setActiveObject(target, e);
            shouldRender = true;
        }
        else {
            var control = target.controls[corner],
                mouseUpHandler = control && control.getMouseUpHandler(e, target, control);
            if (mouseUpHandler) {
                pointer = this.getPointer(e);
                mouseUpHandler(e, transform, pointer.x, pointer.y);
            }
        }
        if (target.isSentEditing) {
            if (!transform.aborted) {
                if (target.type === 'curvedLine') {
                    setLinePointsForCurrentPosition(target);
                    if (transform?.editingMethods && transform.editingMethods.has(EDITING_METHODS.LINE_POINT_UPDATE)) {
                        detachControl(this, target, { isContinuous: true, processId: target.canvasEventsProcessId })
                    }
                    target.onShapeChanged();
                    target.calculateBoundingBoxForCurvedLine();
                }
                this.collaborationManager.completeContinuousEditing(
                    target.canvasEventsProcessId
                )
            }
            target.isSentEditing = false;
            target.canvasEventsProcessId = null;
            transform.isContinuous = null;
        }
        target.isMoving = false;
    }
    // if we are ending up a transform on a different control or a new object
    // fire the original mouse up from the corner that started the transform
    if (transform && (transform.target !== target || transform.corner !== corner)) {
        var originalControl = transform.target && transform.target.controls[transform.corner],
            originalMouseUpHandler = originalControl && originalControl.getMouseUpHandler(e, target, control);
        pointer = pointer || this.getPointer(e);
        originalMouseUpHandler && originalMouseUpHandler(e, transform, pointer.x, pointer.y);
    }
    this._setCursorFromEvent(e, target);
    this._handleEvent(e, 'up', LEFT_CLICK, isClick);
    this._groupSelector = null;
    this._currentTransform = null;
    // reset the target information about which corner is selected
    target && (target.__corner = 0);
    if (shouldRender) {
        this.requestRenderAll();
    }
    else if (!isClick) {
        this.renderTop();
    }
}

/**
 * Handles mouse events.
 * @override
 */
export function _handleEvent(e, eventType, button, isClick) {
    var target = this._target,
        targets = this.targets || [],
        options = {
            e: e,
            target: target,
            subTargets: targets,
            button: button || LEFT_CLICK,
            isClick: isClick || false,
            pointer: this._pointer,
            absolutePointer: this._absolutePointer,
            transform: this._currentTransform
        };
    if (eventType === 'up') {
        options.currentTarget = this.findTarget(e);
        options.currentSubTargets = this.targets;
    }
    this.fire('mouse:' + eventType, options);

    let shouldFireEvent = true;

    if (shouldFireEvent) {
        target && target.fire('mouse' + eventType, options);
    }
    for (var i = 0; i < targets.length; i++) {
        targets[i].fire('mouse' + eventType, options);
    }
}