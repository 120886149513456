import UsersMultipleNeutral2 from '../components/svgIcons/UsersMultipleNeutral2';
import DeleteCircle from '../components/svgIcons/DeleteCircle';
import Earth3 from '../components/svgIcons/Earth3';

import DiamondIcon from '../components/svgIcons/canvasRedesign/IconDiamond';
import EllipseIcon from '../components/svgIcons/canvasRedesign/IconEllipse';
import EraserIcon from '../components/svgIcons/canvasRedesign/IconEraser';
import LineIcon from '../components/svgIcons/canvasRedesign/IconLine';
import Parallelogram from '../components/svgIcons/canvasRedesign/IconParallelogram';
import PenIcon from '../components/svgIcons/canvasRedesign/IconPen';
import TriangleIcon from '../components/svgIcons/canvasRedesign/IconTriangle';
import RectangleIcon from '../components/svgIcons/canvasRedesign/IconRectangle';
import ConnectorIcon from '../components/svgIcons/canvasRedesign/IconConnector';
import StickyNoteIcon from '../components/svgIcons/canvasRedesign/IconStickyNote';
import FrameIcon from '../components/svgIcons/canvasRedesign/IconFrame';
import {KEYS} from './shortcuts/Keys';

export const apiEndpoints = {
    createWhiteBoard: '/whiteboards',
    deletedWhiteBoardList: '/whiteboards/trash',
    restoreWhiteboard: '/whiteboards/{wbId}/restore',
    duplicateWhiteboard: '/whiteboards/{wbId}/duplicate',
    deleteWhiteboard: '/whiteboards/{wbId}/delete',
    getWhiteBoardDetails: '/whiteboards/{wbId}',
    uploadImageUrl: '/attachments/{wbId}/bulkUploadImages',
    registerUser: '/auth/register',
    loginWithEmailPassword: '/auth/login',
    loginWithGoogle: '/auth/googleSignIn',
    forgotPassword: '/auth/forgotPassword',
    emailPasswordChange: '/auth/emailPasswordChange',
    resetPassword: '/auth/resetPassword',
    getComments: '/whiteboards/{wbId}/comments',  // getting comments for CommentTab. Pagination enabled
    getInitComments: '/whiteboards/{wbId}/commentsInit',  // for rendering comment icons
    getCommentThread: '/whiteboards/{wbId}/commentThread',  // for getting comment thread of single comment
    getTaggedComments: '/whiteboards/{wbId}/commentMentions',  // pagination enabled
    searchComments: '/whiteboards/{wbId}/searchComments',  // pagination enabled
    inviteUser: '/whiteboards/{wbId}/invite',
    updateUserPermission: '/whiteboardUsers/{wbId}/userPermission',
    removeUserAccess: '/whiteboardUsers/{wbId}/removeUser',
    publicShareBoard: '/whiteboards/{wbId}/public-share',
    getUsers: '/whiteboards/{wbId}/users',
    getUserRecommendations: '/whiteboards/{wbId}/recommendation',
    getActivityLogs: '/whiteboards/{wbId}/activityLog',  // pagination enabled
    automatedLogin:'/auth/builderLogin',
    automatedEnterpriseLogin:'/enterprise/whiteboard/login',
    getAutomatedBoard:'/whiteboards/builder/{id}',
    getAutomatedBMeetBoard:'/external/bmeet/whiteboards/{id}',
    getAutomatedEnterpriseBoard:'/enterprise/whiteboard/{id}?permission={permissionType}',
    checkForAperios:'/api/v1/prototype/build_cards/{studio_build_card_id}/whiteboards/board_type',
    submitFeedback: '/whiteboards/{wbId}/submitFeedback',
    userPut: '/users/{userId}',
    importUserFlow: '/whiteboards/bnow/importUserFlow/:buildCardID',
    checkRequestAccess: '/whiteboards/{wbId}/request-access',
    sendRequestAccess: '/whiteboards/{wbId}/send-request-access',
    acceptRequestAccess: '/whiteboards/{wbId}/accept-request-access',
    rejectRequestAccess: '/whiteboards/{wbId}/reject-request-access',
    pendingRequestList: '/whiteboards/{wbId}/board-requests',
    migrationImportImages: '/migration-run/{wbSlugId}/run-images',
    migrationImportComments: '/migration/{wbSlugId}/addComments',
    migrationImportMembers: '/migration/{wbSlugId}/addWBUsers',
    migrationBNOWGetUser: '/api/v1/prototype/build_cards/whiteboards/fetch_whiteboard_user?guid={guid}',
    migrationBNOWGetUserGUID: `/api/v1/prototype/build_cards/{buildCardId}/fetch_user_guid?email={email}`
};

export const modes = {
    RECTANGLE: 'RECTANGLE',
    TRIANGLE: 'TRIANGLE',
    RHOMBUS: 'RHOMBUS',
    PARALLELOGRAM : 'PARALLELOGRAM',
    ELLIPSE: 'ELLIPSE',
    LINE: 'LINE',
    PENCIL: 'PENCIL',
    ERASER: 'ERASER',
    STICKY:'STICKY',
    FRAME: 'FRAME',
    TEXT: 'TEXT',
    SELECT: 'SELECT',
    PAN: 'PAN',
    COMMENT: 'COMMENT',
    LASSO: 'LASSO',
    ARROW: 'ARROW',
    TABLE: 'TABLE'
};

export const penShape = {
    shapeName :'Pen',
    shapeMode :'pencilMode',
    shapeButton:'PENCIL',
    shapeSvg: PenIcon,
    shapeType : 'pencil'
}

export const arrowShape = {
    shapeName :'Connector',
    shapeMode :'arrowMode',
    shapeButton:'ARROW',
    shapeSvg: ConnectorIcon,
    shapeType : 'arrow'
}

export const rectShape = {
    shapeName :'Rectangle',
    shapeMode :'rectMode',
    shapeButton:'RECTANGLE',
    shapeSvg: RectangleIcon,
    shapeType : 'primeShapes'
}

export let constShapes =[
    {
        shapeName :'Ellipse',
        shapeMode :'ellipseMode',
        shapeButton:'ELLIPSE',
        shapeSvg: EllipseIcon,
        shapeType : 'primeShapes'
    },
    {
        shapeName :'Triangle',
        shapeMode :'triangleMode',
        shapeButton:'TRIANGLE',
        shapeSvg: TriangleIcon,
        shapeType : 'primeShapes'
    },
    {
        shapeName: 'Diamond',
        shapeMode: 'rhombusMode',
        shapeButton: 'RHOMBUS',
        shapeSvg: DiamondIcon,
        shapeType: 'primeShapes'
    },
    {
        shapeName: 'Parallelogram',
        shapeMode: 'parallelogramMode',
        shapeButton: 'PARALLELOGRAM',
        shapeSvg: Parallelogram,
        shapeType: 'primeShapes'
    },
    rectShape,
    penShape,
    {
        shapeName :'Eraser',
        shapeMode :'eraseMode',
        shapeButton:'ERASER',
        shapeSvg: EraserIcon,
        shapeType : 'pencil'
    },
    arrowShape,
    {
        shapeName :'Line',
        shapeMode :'lineMode',
        shapeButton:'LINE',
        shapeSvg: LineIcon,
        shapeType : 'arrow'
    }
]

export const customFields = ['shapeType', 'whiteBoardId', 'uuid', 'createdBy', 'modifiedBy', 'isDeleted', 'lines', 'leftPolygon', 'leftDeltaX', 'leftDeltaY', 'rightPolygon', 'rightDeltaX', 'rightDeltaY', 'etag' ,'url' ,'size' ,'lockMovementX', 'lockMovementY' ,'hasBorders' ,'hoverCursor' ,'_controlsVisibility' ,'crossOrigin' ,'isLocked' ,'lockScalingFlip' ,'flipX' ,'flipY','hasControls', 'stackOrder', 'hasHyperlink', 'title', 'rows', 'cols', 'cells', 'stackOrders', 'zIndex', 'curvedLineVersion'];

export const AUTH_VALIDATION_MESSAGE = {
    FIELD_REQUIRED: 'Field cannot be empty',
    EMAIL_REQUIRED: 'Email is required',
    ENTER_VALID_EMAIL: 'Please enter a valid email address',
    VALID_PHONE_NUMBER_REQUIRED: 'Valid phone number is required',
    PASSWORD_REQUIRED: 'Password is required',
    PASSWORD_MINIMUM_LENGTH: 'Enter minimum 8 characters',
    PASSWORD_MAXIMUM_REQUIRED: 'Password must be shorter than 50 characters',
    PASSWORD_CONTAIN_LOWERCASE:
    'Password must be contain at least 1 lowercase character',
    PASSWORD_CONTAIN_UPPERCASE:
    'Password must be contain at least 1 uppercase character',
    PASSWORD_CONTAIN_NUMBER:
    'Password must be contain at least 1 number character',
    INVALID_CHARACTER: 'Invalid characters',
    NEED_THREE_CHARACTER: 'Name needs to be at least three characters',
    IS_REQUIRED: 'is required',
    PASSWORD_RESET_SUCCESSFULLY: 'Password reset successfully',
    COULD_NOT_COMPLETE_ACTION: 'Could not complete your action, please try again',
    PLEASE_SELECT_TERM: 'Please agree to the terms and conditions',
    PASSWORD_NOT_MATCH: 'Passwords do not match',
    PASSWORD_NOT_RESET: 'Password was not reset, please try again',
    BOARD_UNAUTHORIZED_VIA_PUBLIC_SHARABLE: 'General access is deactivated for this board. Only members can access this board from the Shared with Me section',
    BOARD_UNAUTHORIZED: 'You don\'t have the necessary permissions to view this board.',
    LOGIN_IS_REQUIRED: 'Please login to continue!',
    PASSWORD_MIN_EIGHT_CHARS: 'Enter minimum 8 characters',
    PASSWORD_CONTAIN_LOWERCASE_UPPERCASE: 'At least 1 uppercase and 1 lowercase letter',
    PASSWORD_MIN_NUMBER: 'At least 1 number',
    NO_SPECIAL_CHARACTER: 'No special characters are allowed',
    NAME_REQUIRED: 'Name is required',
    NO_NUMBERS: 'No numbers are allowed'
};

export const CONFIRMATION_STATEMENTS = [AUTH_VALIDATION_MESSAGE.PASSWORD_MINIMUM_LENGTH, AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_UPPERCASE, AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_LOWERCASE, AUTH_VALIDATION_MESSAGE.PASSWORD_CONTAIN_NUMBER];

export const BOARD_MESSAGES = {
    WB_LINK_COPIED: 'Whiteboard link successfully copied',
    USER_NOT_ALLOWED_TO_CHANGE_PERMISSION: 'You are not allowed to change the permission of a user',
    INVALID_INPUT: 'Please enter valid input.',
    TABLES_CANNOT_HAVE_SAME_NAMES: 'Tables cannot have same names',
    USER_HAVENT_SIGNED_UP_YET: 'User hasn\'t signed up yet.',
    FRAME_NOT_FOUND: 'Frame is deleted from the board.'
}

export const TEXT_EDITOR_PLACEHOLDER = 'Type something';

export const SOCKET_STATUS_MODS = {
    IDLE: 'IDLE',
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    RECONNECT_ATTEMPT: 'RECONNECT_ATTEMPT',
    FAIL_TO_CONNECT: 'FAIL_TO_CONNECT'
}

export const LEFT_DRAWER_TABS = {
    ACTIVITIES: 'ACTIVITIES',
    COMMENTS: 'COMMENTS',
    FRAMES: 'FRAMES',
}

// in minutes
export const FEEDBACK_TIMEOUT = 30;
export const FEEDBACK_TEXT_LENGTH_LIMIT = 1000;

// guide lines
export const GUIDE_LINE_ALIGN_THRESHOLD = 5;
export const GUIDE_LINE_COLOR = '#B388FF';
export const GUIDE_LINE_WIDTH = 2;
export const GUIDE_LINE_DASH = [5, 10];
export const GUIDE_LINE_OFFSET = 20;

export const TOOLBAR_DISPACH_ACTIONS = {
    SET_TARGET: 'SET_TARGET',
    HIDE: 'HIDE',
    SHOW: 'SHOW',
    REMOVE: 'REMOVE',
    TOGGLE_COLOR_PICKER: 'TOGGLE_COLOR_PICKER',
    CLOSE_ALL_COLOR_PICKERS: 'CLOSE_ALL_COLOR_PICKERS',
    CHANGE_COLOR: 'CHANGE_COLOR',
    TOGGLE_MENU: 'TOGGLE_MENU',
    CHANGE_SELECTION_TEXT_BOX :'CHANGE_SELECTION_TEXT_BOX',
    CHANGE_SELECTION_EDIT_TEXT_BOX: 'CHANGE_SELECTION_EDIT_TEXT_BOX', // for editor textboxes
    UPDATE_COLORS: 'UPDATE_COLORS'
}

export const TOOLBAR_MENU = {
    LINE_TYPE: 'LINE_TYPE',
    ARROW_TYPE: 'ARROW_TYPE',
    TEXT_ALIGN: 'TEXT_ALIGN',
    FONT_STYLE: 'FONT_STYLE',
    HYPERLINK: 'HYPERLINK',
    CHANGE_SHAPE: 'CHANGE_SHAPE',
    FILTERS_MENU: 'FILTERS_MENU',
    TOGGLE_COLOR_PICKER: 'TOGGLE_COLOR_PICKER'
}

export const TOOLBAR_MENU_NAMES = {
    LINE_TYPE: 'showLineType',
    ARROW_TYPE: 'showArrowType',
    TEXT_ALIGN: 'showTextAlign',
    FONT_STYLE: 'showFontStyle',
    HYPERLINK: 'showHyperlinkBox',
    CHANGE_SHAPE: 'showChangeShape',
    FILTERS_MENU: 'showFilters',
}

export const TOOLBAR_INITIAL_STATE = {
    target: null,
    shape: null,
    hide: true,
    subType: null,
    subTargetObject: null,
    innerColorObj: {
        displayColorPicker: false,
        color: ''
    },
    outerColorObj: {
        displayColorPicker: false,
        color: ''
    },
    textColorObj: {
        displayColorPicker: false,
        color: ''
    },
    highlightTextColorObj:{
        displayColorPicker: false,
        color: ''
    },
    menu: {
        [TOOLBAR_MENU_NAMES.LINE_TYPE]: false,
        [TOOLBAR_MENU_NAMES.ARROW_TYPE]: false,
        [TOOLBAR_MENU_NAMES.TEXT_ALIGN]: false,
        [TOOLBAR_MENU_NAMES.FONT_STYLE]: false,
        [TOOLBAR_MENU_NAMES.HYPERLINK]: false,
        [TOOLBAR_MENU_NAMES.CHANGE_SHAPE]: false,
    }
}

export const SOCKET_EVENT = {
    MODIFIED: 'shapeModified',
    CREATED: 'shapeCreated',
    DELETED: 'shapeDeleted',
    USER_INVITED: 'userInvited',
    USER_ROLE_UPDATED: 'updated-WBUserPermission',
    USER_ROLE_REMOVED: 'removed-WBUser',
    OWNERSHIP_TRANSFFERRED: 'ownershipTransferred',
    OWNER_UPDATED: 'ownerUpdated',
    UPDATE_PUBLIC_USER_PERMISSION: 'sharedWBPermissions',
    WP_PERMISSIONS_UPDATED: 'sharedWBPermissionsUpdated',
    WHITEBOARD_STATUS: 'whiteboardStatus',
    ON_COLLABORATORS_UPDATED: 'wbOnlineUsersUpdated',
    BOARD_NAME_UPDATED: 'renameBoard',
    COLOR_SELECTED: 'colorSelected', // To update recent selected colors.
    RESPONSE_ACCESS: 'responseAccess'
}

export const SOCKET_EVENT_TO_HISTORY_ACTION = {
    [SOCKET_EVENT.MODIFIED]: 'modified',
    [SOCKET_EVENT.CREATED]: 'created',
    [SOCKET_EVENT.DELETED]: 'deleted',
}

export const SOCKET_CHUNK_SIZE = 100;

export const IMAGE_LOADING_CHUNK_SIZE = 10;

// Tutorials
export const TUTORIAL_STEPS = [
    {
        selector: 'select',
        title: 'Select',
        position: 'x',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/select.gif',
    },
    {
        selector: 'pan',
        title: 'Pan',
        position: 'x',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/pan.gif',
    },
    {
        selector: 'comment',
        title: 'Comment',
        position: 'y',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/comments.gif',
    },
    {
        selector: 'sticky-note',
        title: 'Sticky Note',
        position: 'x',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/sticky-note.gif',
    },
    {
        selector: 'frame',
        title: 'Frame',
        position: 'x',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/frame.gif',
    },
    {
        selector: 'undo-redo',
        title: 'Undo/Redo',
        position: 'x',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/undo.gif',
    },
    {
        selector: 'activity-logs',
        title: 'Activity Logs',
        position: 'y',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/activity-log.gif',
    },
    // {
    //   selector: 'mini-map',
    //   title: 'Mini Map',
    //   position: 'y'
    // },
    {
        selector: 'fit-to-screen',
        title: 'Fit to Screen',
        position: 'y',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/fit-to-screen.gif',
    },
    {
        selector: 'shortcuts',
        title: 'Shortcuts',
        position: 'y',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/shortcuts.gif',
    },
    {
        selector: 'import-export',
        title: 'Import/Export',
        position: 'y',
        gifUrl: 'https://minio.apeiros.prod.eastus.az.svc.builder.ai/sbucket/tutorials/gifs/import-export.gif'
    },
]

export const TUTORIAL_ORDER = TUTORIAL_STEPS.map((step) => step.selector);

export const SEGMENT_ANALYTICS_CONSTANTS = {
    WHITEBOARD_REFRESHED: 'whiteboard_refreshed',
    WHITEBOARD_DOWNLOADED: 'whiteboard_downloaded',
    WHITEBOARD_SHARED: 'whiteboard_shared',
    WHITEBOARD_ID: 'whiteboard_id',
    USER_ID: 'user_id',
    DOWNLOAD_TYPE: 'download_type',
    USER_BROWSER: 'user_browser',
    USER_DEVICE: 'user_device',
    WHITEBOARD_REFRESHED_DESCRIPTION: 'User refreshes the browser',
    WHITEBOARD_DOWNLOADED_DESCRIPTION: 'User downloads the whiteboard',
    WHITEBOARD_SHARED_DESCRIPTION: 'User shares the whiteboard',
    DOWNLOAD_TYPE_PNG: 'PNG',
    DOWNLOAD_TYPE_JPEG: 'JPEG',
    DOWNLOAD_TYPE_SVG: 'Vector',
    DEVICE_TYPE_DESKTOP: 'DESKTOP',
    DEVICE_TYPE_MOBILE: 'MOBILE',
    DEVICE_TYPE_TABLET: 'TABLET',
};

// LINE CONSTANTS
export const LINE_POLYGON_DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right',
}
export const LINE_POLYGON_POINTS = [LINE_POLYGON_DIRECTIONS.LEFT, LINE_POLYGON_DIRECTIONS.RIGHT];

export const LINE_ENDS_BY_POLYGON_SIDE = {
    [LINE_POLYGON_DIRECTIONS.LEFT]: 'start',
    [LINE_POLYGON_DIRECTIONS.RIGHT]: 'end',
}

export const LINE_POLYGON_PREFIXES = {
    [LINE_POLYGON_DIRECTIONS.LEFT]: {
        polygon: 'leftPolygon',
        deltaX: 'leftDeltaX',
        deltaY: 'leftDeltaY',
    },
    [LINE_POLYGON_DIRECTIONS.RIGHT]: {
        polygon: 'rightPolygon',
        deltaX: 'rightDeltaX',
        deltaY: 'rightDeltaY',
    },
}

export const MAGNET_CIRCLE_TYPE = 'magnetCircle';
export const HIGHLIGHT_TYPE = 'highlight';
export const DUPLICATE_SHADOW_OBJECT_TYPE = 'duplicateShadowObj';
export const DUPLICATE_SHADOW_GROUP_TYPE = 'duplicateShadowObjGroup';
export const TEXTBOX_TYPE = 'textbox';

export const DISABLED_OBJECTS_FOR_HIDING_SUBTOOLBAR = [
    MAGNET_CIRCLE_TYPE,
    HIGHLIGHT_TYPE,
    DUPLICATE_SHADOW_OBJECT_TYPE,
    DUPLICATE_SHADOW_GROUP_TYPE,
]

export const GOOGLE_CLIENT_ID =
  '291678137665-2e8jhctlu00kujaks0iav76csnn7mc05.apps.googleusercontent.com';

export const USER_ROLES = {
    view: { display: 'View Only', id: 'view', tier: 3 },
    comment: { display: 'Comments', id: 'comment', tier: 2 },
    edit: { display: 'Edit', id: 'edit', tier: 1 },
    notAllowed: {
        display: 'No access',
        id: 'NOT_ALLOWED',
        icon: <DeleteCircle height={16} width={16} />,
        border: 'top',
        tier: 4,
    },
    removeAccess: {
        display: 'Remove Access',
        id: 'removeAccess',
        icon: <DeleteCircle height={16} width={16} />,
        border: 'top',
    },
};

export const DEFAULT_SHARED_PERMISSIONS = {
    users: 'NOT_ALLOWED',
    employees: 'NOT_ALLOWED',
};

export const AVAILABLE_PERMISSION_PER_USER = {
    users: ['view', 'comment', 'notAllowed'],
    employees: Object.keys(USER_ROLES).slice(0, -1),
};

export const SHARED_PERMISSION_OPTIONS = [
    {
        icon: <Earth3 />,
        display: 'Anyone with the link can',
        name: 'users',
    },
    {
        icon: <UsersMultipleNeutral2 />,
        display: 'Everyone at Builder.ai can',
        name: 'employees',
    },
];

export const SHAPE_DEFAULTS = {
    FONT_SIZE: 60,
    FONT_FAMILY: 'Rubik, sans-serif',
    TEXT_COLOR: 'rgba(60, 62, 73, 1)',
    STROKE_WIDTH: 2,
    FILL: 'transparent',
    STROKE: 'rgba(60, 62, 73, 1)',
    PLACEHOLDER_COLOR: 'rgba(192, 195, 206, 1)',
    BACKGROUND_COLOR: 'rgba(0, 0, 0, 0)',
    STICKY_NOTE_BACKGROUND: 'rgba(254, 255, 167, 1)',
    FRAME_BACKGROUND: 'rgba(244, 240, 253, 1)'
}

export const SHAPE_DEFAULTS_AS_SHAPE_PROPS = {
    strokeWidth: SHAPE_DEFAULTS.STROKE_WIDTH,
    fill: SHAPE_DEFAULTS.FILL,
    stroke: SHAPE_DEFAULTS.STROKE,
}

export const TEXT_SIZE_OPTIONS = [
    10,
    12,
    14,
    18,
    24,
    36,
    48,
    64,
    80
];

export const DEFAULT_TEXT_SIZE = 14

const EMPTY_SPACE = '  ';
export const PLACEHOLDER_TEXT = EMPTY_SPACE + 'Write something here';

export const LINE_TYPES = {
    STRAIGHT: 'straight',
    CURVED: 'curved',
    STEPPER: 'stepper',
}

export const FLOWCHART_MODAL_TYPES = {
    IMPORT: 'import',
    REMOVE: 'remove',
    LOADING: 'loading',
}

export const FLOWCHART_MODAL_TEXTS = {
    [FLOWCHART_MODAL_TYPES.IMPORT]: {
        title: 'Import user flow',
        info: 'Are you sure you want to import the user flows?',
        cancelBtn: 'Cancel',
        confirmBtn: 'Import',
    },
    [FLOWCHART_MODAL_TYPES.REMOVE]: {
        title: 'Remove user flow',
        info: 'Are you sure you want to remove the user flows?',
        cancelBtn: 'Cancel',
        confirmBtn: 'Remove',
    },
    [FLOWCHART_MODAL_TYPES.LOADING]: {
        info: 'Please wait... Importing user flows',
    }
}

export const FLOWCHART_STATUS = {
    PENDING: 'pending',
    IN_PROGRESS: 'inProgress',
    COMPLETED: 'completed',
    REMOVED: 'removed',
}

export const NIMA_IMPORT_STATUS = {
    PENDING: 'pending',
    IN_PROGRESS: 'inProgress',
    COMPLETED: 'completed',
}

export const FLOWCHART_ITEM_TYPE = {
    FEATURE: 'feature',
    SUB_FEATURE: 'subFeature',
    HOTSPOT: 'hotspot',
    FRAME: 'frame',
    SUB_FRAME: 'subFrame',
}

export const FLOWCHART_ROLE_DEVICE_PADDING = 1500;
export const FLOWCHART_PADDING_X = 600;
export const FLOWCHART_PADDING_Y = 500;
export const FLOWCHART_FRAME_PADDING = 100;
export const FLOWCHART_SUB_FEATURE_PADDING_X = 300;
export const FLOWCHART_SUB_FEATURE_PADDING_Y = 200;
export const FLOWCHART_FEATURE_STROKE_WIDTH = 2;

export const SHAPE_TYPES = {
    TRIANGLE: 'triangle',
    RECT: 'rect',
    ELLIPSE: 'ellipse',
    RHOMBUS: 'rhombus',
    PARALLELOGRAM : 'parallelogram',
}

export const ALLOWED_CONNECTORS_DUPLICATION_SHAPE_TYPES = [
    SHAPE_TYPES.TRIANGLE,
    SHAPE_TYPES.RECT,
    SHAPE_TYPES.ELLIPSE,
    SHAPE_TYPES.RHOMBUS,
    SHAPE_TYPES.PARALLELOGRAM,
]

export const CANVASES_CTX_ACTIONS = {
    SET_CANVAS: 'setCanvas',
    RESET_CANVASES: 'resetCanvases'
}

export const EMITTER_TYPES = {
    CHANGE_SELECT_MODE: 'change-select-mode',
    CREATE_GENERIC_SHAPE: 'create-generic-shape',
    CREATE_SHAPE: 'create-shape',
    EMIT_ON_MOUSE_DOWN: 'emit-on-mouse-down',
    CHANGE_ACTION_MODE: 'change-action-mode',
    FIT_TO_SCREEN: 'fit-to-screen',
    TOOLBAR_SHOW: 'toolbar-show',
    TOOLBAR_HIDE: 'toolbar-hide',
    TOOLBAR_REMOVE: 'toolbar-remove',
    OPEN_SEARCH_TEXT: 'open-search-text',
    TOGGLE_SHORTCUT_LIST: 'toggle-shortcut-list',
    PAGE_CHANGED: 'page-changed',
    FLOWCHART_ITEM_ADDED: 'flowchart-item-added',
    FLOWCHART_ITEM_REMOVED: 'flowchart-item-removed',
    SET_UNREAD_COMMENT_EXISTS: 'set-unread-comments-exists',
    CHECK_UNREAD_COMMENTS: 'check-unread-comments',
    OBJECT_MUTATED: 'object-mutated',
    DESELECT_COMMENTS: 'deselect-comments',
    DEAUTHORIZE_THE_USER: 'deauthorize-the-user',
    HISTORY_ENABLED: 'history-enabled',
    NIMA_CANVAS_INITIALIZED: 'nima-canvas-initialized',
    RESET_HYPERLINK_VALUE: 'reset-hyperlink-value',
    COLLAB_LOCK: 'collab-lock',
    TOOLBAR_UPDATE: 'toolbar-update', // re render toolbar
}

export const RIGHT_DRAWER_WIDTH = 274;

export const RIGHT_DRAWER_TITLES = {
    PAGES: 'Pages',
}

export const PAGE_ITEM_DROPDOWN_MENU = [
    { id: 'edit', label: 'Edit Name' },
    { id: 'delete', label: 'Delete Page' },
    { id: 'copy', label: 'Copy Page' },
]

export const BOARD_ITEM_DROPDOWN_MENU = [
    { id: 'copy', label: 'Duplicate whiteboard', public:true },
    { id: 'delete', label: 'Delete whiteboard', public:false }
]

export const PAGE_SOCKET_EVENTS = {
    PAGE_CREATED: 'pageCreated',
    PAGE_NAME_CHANGED: 'pageNameChanged',
    PAGE_DELETED: 'pageDeleted',
    PAGE_DUPLICATED: 'duplicatePage'
}

export const IMAGE_TITLE_FONT_SIZE = 12;

export const MODAL_WINDOWS = {
    FLOWCHART: 'flowchart',
    FEEDBACK: 'feedback',
    SHORTCUT_LIST: 'shortcutList',
    ACTIVITY_LOGS: 'activityLogs',
    RIGHT_DRAWER: 'rightDrawer',
    MINI_MAP: 'miniMap',
    INVITE: 'invite',
    TUTORIAL: 'tutorial',
    SEARCH: 'search',
    COMMENT_DRAWER: 'commentDrawer',
    DENY_ACCESS: 'denyAccess',
    REQUEST_EDIT: 'requestEdit',
    REQUEST_EDIT_APPROVAL: 'requestEditApproval',
    REQUEST_EDIT_DENY: 'requestEditDeny'
}
export const MAX_TABLE_BOX_ROW_SIZE = 15;
export const MAX_TABLE_BOX_COL_SIZE = 15;
export const MIN_TABLE_BOX_ROW_SIZE = 5;
export const MIN_TABLE_BOX_COL_SIZE = 10;

export const TABLE_DEFAULTS = {
    titleBoxPadding: 7,
    titleBoxHeight: 31,
    cellBorderWidth: 1,
    fontSize: 14,
    defaultCellWidth: 351,
    defaultCellHeight: 195,
    borderRadius: 4,
    cellTextPadding: 8,
    maxCharacterCount: 6000
}

export const NON_ROTATABLE_OBJECT_TYPES = ['frame', 'textbox', 'table'];
export const TABLE_NOT_ALLOWED_CONTROL_TYPES = ['rotate', 'ml', 'mr', 'mt', 'mb', 'expanseLeft', 'expanseRight', 'expanseTop', 'expanseBottom'];

export const TEXTBOX_LINE_HEIGHT = 1.16 * 1.13;  // lineHeight * _fontSizeMult

export const LINE_ATTACHABLE_OBJECT_TYPES = ['group', 'textbox', 'optimizedImage'];

export const COMMENT_DRAWER_WIDTH = 283;

export const FILTER_OPTIONS = [
    'Show all', 'Show resolved', 'Show unread', 'Mentions'
];

export const FILTER_OPTIONS_REQUESTS = {
    'Show all': 'Showall',
    'Show resolved': 'resolved',
    'Show unread': 'unread',
    'Mentions': 'mentions'
}

export const InputFormClosingClassnames = ['upper-canvas', 'commentInput--input', 'input-container active', 'input-container', 'commentInput--btn', 'commentInput--btn disabled', 'commentInput--btn--icon', 'tooltiptext', 'mention__item selected'];

export const OBJECT_EVENTS = {
    SHAPE_CHANGED: 'shapeChanged',
    TRANSFORM_CHANGED: 'transformChanged',
    ORDER_CHANGED: 'orderChanged',  // stack order
    VISIBILITY_CHANGED: 'visibilityChanged',  // stack order
}

export const MOVE_DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right',
    UP: 'up',
    DOWN: 'down',
}

export const MOVE_DIRECTIONS_FROM_KEY = {
    [KEYS.ArrowLeft]: MOVE_DIRECTIONS.LEFT,
    [KEYS.ArrowRight]: MOVE_DIRECTIONS.RIGHT,
    [KEYS.ArrowUp]: MOVE_DIRECTIONS.UP,
    [KEYS.ArrowDown]: MOVE_DIRECTIONS.DOWN,
}

export const APP_NAMES = {
    TEAMBOARD: 'TEAMBOARD',
    BMEET: 'BMEET',
    ENTERPRISE: 'ENTERPRISE',
    APEIROS: 'APEIROS'
}

export const SUBTOOLBAR_FILTER_ITEMS = {
    STICKY: { id: 'STICKY', text: 'Sticky note', icon: <StickyNoteIcon width={16} height={16} /> },
    SHAPE: { id: 'SHAPE', text: 'Shape', icon: <RectangleIcon width={16} height={16} /> },
    TEXT: { id: 'TEXT', text: 'Text', icon: <em className="icon-canvas-redesign-text" style={{ fontSize: '16px'}} /> },
    FRAME: { id: 'FRAME', text: 'Frame', icon: <FrameIcon width={16} height={16} /> },
    PEN: { id: 'PEN', text: 'Pen', icon: <PenIcon width={16} height={16} /> },
    CONNECTOR: { id: 'CONNECTOR', text: 'Connector', icon: <ConnectorIcon width={16} height={16} />  },
}

export const SUBTOOLBAR_ITEMS = {
    DELETE: { id: 'DELETE' },
    LOCK: { id: 'LOCK' },
    DUPLICATE: { id: 'DUPLICATE' },
    DOWNLOAD: { id: 'DOWNLOAD' },
    CHANGE_SHAPE: { id: 'CHANGE_SHAPE' },
    ALIGNMENT: { id: 'ALIGNMENT' },
    FONT_STYLE: { id: 'FONT_STYLE' },
    HYPERLINK: { id: 'HYPERLINK' },
    FONT_SIZE: { id: 'FONT_SIZE' },
    TEXT_COLOR: {
        id: 'TEXT_COLOR',
        supportedMultiSelectionTypes: [
            SUBTOOLBAR_FILTER_ITEMS.SHAPE.id,
            SUBTOOLBAR_FILTER_ITEMS.STICKY.id
        ]
    },
    OUTER_COLOR: {
        id: 'OUTER_COLOR',
        supportedMultiSelectionTypes: [
            SUBTOOLBAR_FILTER_ITEMS.SHAPE.id,
            SUBTOOLBAR_FILTER_ITEMS.STICKY.id,
            SUBTOOLBAR_FILTER_ITEMS.PEN.id,
            SUBTOOLBAR_FILTER_ITEMS.TEXT.id,
        ]
    },
    HIGHLIGHT_COLOR: {
        id: 'HIGHLIGHT_COLOR',
        supportedMultiSelectionTypes: [
            SUBTOOLBAR_FILTER_ITEMS.TEXT.id
        ]
    },
    INNER_COLOR: {
        id: 'INNER_COLOR',
        supportedMultiSelectionTypes: [
            SUBTOOLBAR_FILTER_ITEMS.SHAPE.id,
            SUBTOOLBAR_FILTER_ITEMS.STICKY.id,
            SUBTOOLBAR_FILTER_ITEMS.FRAME.id,
            SUBTOOLBAR_FILTER_ITEMS.PEN.id,
            SUBTOOLBAR_FILTER_ITEMS.TEXT.id,
            SUBTOOLBAR_FILTER_ITEMS.CONNECTOR.id,
        ]
    },
    LINE_TYPE: {
        id: 'LINE_TYPE',
        supportedMultiSelectionTypes: [
            SUBTOOLBAR_FILTER_ITEMS.CONNECTOR.id
        ]
    },
    ARROW_TYPE: {
        id: 'ARROW_TYPE',
        supportedMultiSelectionTypes: [
            SUBTOOLBAR_FILTER_ITEMS.CONNECTOR.id
        ]
    },
    ADD_LINE_TEXT: { id: 'ADD_LINE_TEXT' },
    LINE_FONT_SIZE: { id: 'LINE_FONT_SIZE' },
    LINE_TEXT_COLOR: { id: 'LINE_TEXT_COLOR' },
    FILTERS: { id: 'FILTERS' },
    SEPERATOR: { id: 'SEPERATOR' },
    MORE: { id: 'MORE' },
}

// determines the padding for showing the object in the correct zoom level
export const ADAPT_ZOOM_LEVEL_PADDING = 0.10;
export const INVERSE_ADAPT_ZOOM_LEVEL_PADDING = 1 - ADAPT_ZOOM_LEVEL_PADDING;

export const requestAccessToastOptions = {
    icon: false,
    autoClose: false,
    className: 'wb_toast wb_toast__access-requests',
    draggable: false,
    toastId: `requests-success-${Math.random()}`
}

export const EDIT_ACCESS_REJECT_MESSAGE = 'Edit request has been rejected.'
export const EDIT_ACCESS_ACCEPT_MESSAGE = 'Edit request has been accepted.'
export const EDIT_ACCESS_SENT = 'Edit request has already been sent to the owner.'
export const ACCESS_ACCEPT_MESSAGE = 'Access request has been accepted.'
export const ACCESS_GRANTED_MESSAGE = 'Access to the whiteboard has been granted.'
export const ACCESS_REJECT_MESSAGE = 'Access request has been denied.'
export const EDITING_METHODS_FOR_TEXT = {
    TEXT_FONT_STYLE: 'textFontStyle',
    TEXT_FONT_SIZE: 'textFontSize',
    TEXT_ALIGN: 'textAlign',
    TEXT_HYPERLINK: 'textHyperlink',
    TEXT: 'text',
    TEXT_TITLE: 'textTitle', // title for frames, tables etc.
}

export const EDITING_METHODS_FOR_LINE = {
    LINE_TYPE: 'lineType',
    LINE_DASH: 'lineDash',
    LINE_THICKNESS: 'lineThickness',
    LINE_ARROW_TYPE: 'lineArrowType',
    LINE_POINT_UPDATE: 'linePointUpdate', // modifying line point
    LINE_POLYGON_LEFT: 'linePolygonLeft',
    LINE_POLYGON_RIGHT: 'linePolygonRight',
}

export const COLOR_EDITING_METHODS = {
    COLOR_TEXT: 'colorText',
    COLOR_INNER: 'colorInner',
    COLOR_OUTER: 'colorOuter',
    COLOR_HIGHLIGHT: 'colorHighlight'
}

export const DIMENSION_EDITING_METHODS = {
    DIMENSION: 'dimension',
}

export const ATTACHMENT_EDITING_METHODS = {
    ATTACHMENT_LINE: 'attachmentLine', // when shape has lines
    ATTACHMENT_FRAME: 'attachmentFrame', // when shape is attached to any frame
}

export const FRAME_EDITING_METHODS = {
    FRAME_ATTACHMENTS: 'frameAttachments' // when the attachments array of frame is changed.
}

export const EDITING_METHODS = {
    ...EDITING_METHODS_FOR_TEXT,
    ...EDITING_METHODS_FOR_LINE,
    ...COLOR_EDITING_METHODS,
    ...DIMENSION_EDITING_METHODS,
    ...ATTACHMENT_EDITING_METHODS,
    ...FRAME_EDITING_METHODS,
    LOCK: 'lock',
    MOVE: 'move',
    ROTATE: 'rotate',
    CHANGE_SHAPE_TYPE: 'changeShapeType'
}

// Editing methods list that will change the props of the group children
export const GROUP_CHILD_OBJECT_EDITS = [
    ...Object.values(EDITING_METHODS_FOR_TEXT),
    EDITING_METHODS.COLOR_INNER,
    EDITING_METHODS.COLOR_OUTER,
    EDITING_METHODS.COLOR_TEXT,
]