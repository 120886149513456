import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './RequestEdit.scss';

import CloseIcon from '../svgIcons/Close';
import Spinner from '../svgIcons/Spinner';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import {sendRequestAccess} from '../../services/RequestAccessService';
import eventEmitter from '../../helpers/EventEmitter';

const RequestEdit = () => {
    const dispatch = useDispatch();
    const textareaRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [creatingRequestEdit, setCreatingRequestEdit] = useState(false);
    const [reasonMessage, setReasonMessage] = useState('');
    
    const {id} = useSelector(state => state?.user);
    const {isPublicAccess} = useSelector(state => state?.board);
    
    const handleCloseRequestEditModal = () => {
        setTimeout(() => {
            dispatch({
                type: 'modal/toggleRequestAccessUpgradeModal',
                payload: {
                    shouldShow: false
                }
            })
        }, 0);
    }
    
    const {
        whiteBoardSlugId,
    } = useSelector((state) => state?.board);
    
    /**
     * @param {Event} event
     * @returns
     */
    const handleShowWarningBorder = (event) => {
        const value = event.target.value;
        
        if (value.trim().length > 200) {
            setReasonMessage(value.trim().substring(0, 200))
            return
        }
        if (event.type === 'change') setReasonMessage(value);
    }
    
    const handleRequestEdit = async () => {
        setCreatingRequestEdit(true);
        let isError = false;
        
        try {
            const response = await sendRequestAccess(whiteBoardSlugId, reasonMessage.trim(), !isPublicAccess, true);
            
            if (response?.success) {
                handleCloseRequestEditModal();
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: { hasUpgradeRequest: true }
                })
            }
        }
        catch (err) {
            isError = true;
            console.log(err)
        }
        finally {
            if (isError) {
                console.log(isError, '')
            }
        }
    }
    
    useEffect(() => {
        setIsLoading(false);
        
        const handlePermissionUpdate = (data) => {
            if(id === data.userId && data.permission === 'edit') handleCloseRequestEditModal();
        }
        
        eventEmitter.on('permissionUpdate', handlePermissionUpdate);
        return () => eventEmitter.off('permissionUpdate', handlePermissionUpdate);
    },[])
    
    return (
        <section className={clsx('requestEditModal', { loading: isLoading })}>
            {isLoading ? (
                <Spinner height={40} width={40} />
            ) : (
                <>
                    <header>
                        <h2>Request to {isPublicAccess ? 'access' : 'edit'}</h2>
                        
                        <button type="button" data-testid="closeBtn" onClick={handleCloseRequestEditModal}>
                            <CloseIcon height={18} width={18} />
                        </button>
                    </header>
                    <main>
                        <span className='requestEditModal--info'>Additional message for owner <span>(Optional)</span></span>
                        <fieldset className="requestEditModal--form">
                            <div onClick={() => textareaRef?.current?.focus()} className="textarea-wrapper">
                                <textarea ref={textareaRef} value={reasonMessage} onChange={handleShowWarningBorder} onBlur={handleShowWarningBorder} placeholder={`Reason for requesting${isPublicAccess ? '' : ' upgraded'} access`} />
                                <span className="length-indicator"> {reasonMessage.trim().length} / 200 </span>
                            </div>
                            <span className='warning-message'>Minimum 20 characters</span>
                            <button
                                className="cancel-request-edit-button"
                                data-testid="cancel-request-edit-button"
                                onClick={handleCloseRequestEditModal}
                                type="submit"
                            >
                                Cancel
                            </button>
                            <div className={clsx('request-edit-button-wrapper', {disabled: reasonMessage.trim().length < 20})}>
                                <button
                                    className="request-edit-button"
                                    data-testid="request-edit-button"
                                    onClick={handleRequestEdit}
                                    type="submit"
                                >
                                    <ClipLoader color="#fff" loading={creatingRequestEdit} size={22} /> {creatingRequestEdit ? '' : 'Send Request'}
                                </button>
                            </div>
                        </fieldset>
                    </main>
                </>
            )}
        </section>
    );
}

RequestEdit.propTypes = {
    deniedUserRef: PropTypes.shape({
        current: PropTypes.object
    })
}

export default RequestEdit;