import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { fabric } from 'fabric';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { debounce } from '../../helpers/OptimizationUtils';
import { getHoveredHyperLink } from '../../helpers/TextWrapHelpers';
import './hyperLinkBox.scss';
import { isUserHasAccessToFeature } from '../../helpers/CommonFunctions';
import { isTargetLocked, rotatePoint } from '../../helpers/FabricMethods';
import { getOriginalStateOfGroup, setOriginalStateForGroup } from '../../helpers/ObjectUtils';
import {EDITING_METHODS} from '../../helpers/Constant';

const HyperLinkBox = ({
    canvas,
    actionSelected
}) => {
    const userAccess = useSelector((state) => state.user?.permission);
    const [hoveredLink, setHoveredLink] = useState(null);
    const [hoveredLinkPositions, setHoveredLinkPositions] = useState(null);
    const [renderKey, forceRender] = useState(Math.random());
    const [isEdit, setIsEdit] = useState(false);
    const hoveredLinkCurrDataRef = useRef({});
    const selectedObjectRef = useRef();
    const inputRef = useRef();

    const isTextboxSelected = useMemo(() => {
        if (hoveredLink === null) { return false; }
        const activeObj = canvas.getActiveObject();

        if (activeObj?.type === 'table') {
            if (![
                selectedObjectRef.current?.uuid,
                selectedObjectRef.current?.tableUuid
            ].includes(activeObj.uuid)) {
                return false;
            }

            const cell = activeObj.getSelectedCell();
            return cell && cell.id === hoveredLinkCurrDataRef.current?.hoveredObject?.cellId;
        }

        return selectedObjectRef.current?.uuid === activeObj?.uuid;
    }, [hoveredLink, renderKey]);

    const shouldDisplayHyperlink = useMemo(() => {
        return (
            actionSelected.selectMode === true
            || actionSelected.dragMode === true
            || (Object.values(actionSelected).every((o) => o === false)) // If not selected anymode
        );
    }, [actionSelected]);

    const onInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            applyNewLink();
        }
    }

    const getActiveTextboxObject = (obj) => {
        if (obj.type === 'group' && obj.editingTextObj) {
            return obj.editingTextObj;
        } else if (obj.type === 'group') {
            return obj.getObjects()[1]
        }

        return obj;
    }

    /**
     * After moves the group objects, we need to render all characters in order to update hyperlink positions.
     * There may some improvement for performance reasons. Currently, the best solution that I found is this fn with debounced 5000ms.
     * @param {fabric.Object} obj 
     * @returns 
     */
    const triggerRenderCharacters = (obj) => {
        if (!obj) return;
        if (obj.type !== 'group') return;

        const textboxObj = getActiveTextboxObject(obj);
        if (!textboxObj) return;

        textboxObj._renderTextCommon(canvas.getContext('2d', 'fillText'));
    }

    /**
     * Fire some events to update the changes to other parts of the whiteboard.
     * @param {fabric.Object} obj - Active canvas object. It can be textbox or group.
     * @param {fabric.Object} textboxObj - If the active canvas object is group, this is the textbox object inside of the group.
     */
    const sendUpdatesOfTargetObject = (obj, textboxObj) => {
        // update activity logs
        canvas.fire('modified-with-event', { target: obj });

        // We need to trigger _renderChars function. For the group objects;
        // sometimes that function is not triggered and we need to calculate hyperlink positions in that function.
        if (obj.type === 'group') {
            textboxObj._renderTextCommon(canvas.getContext('2d', 'fillText'));
            const originalGroupState = getOriginalStateOfGroup(obj);
            obj.addWithUpdate();
            setTimeout(() => {
                setOriginalStateForGroup(obj, originalGroupState);
                canvas.renderAll();
            }, 0);
        }
    }

    /**
     * Resetting component state after some actions.
     */
    const resetHyperlinkState = useCallback(() => {
        if (inputRef.current) { inputRef.current.value = ''; }
        setIsEdit(false);
        setHoveredLinkPositions(null);
        setHoveredLink(null);
        hoveredLinkCurrDataRef.current = {};
    }, [setIsEdit, setHoveredLinkPositions, setHoveredLink]);

    /**
     * Applies the new link to the selection area instead of previous one.
     */
    const applyNewLink = () => {
        if (!isUserHasAccessToFeature('hyperlink_apply', userAccess)) return;

        let val = inputRef.current.value;
        if (!val || (val && val.trim().length === 0)) return;

        // First remove the all spaces.
        val = val.replaceAll(/\s+/g, '');

        // If value doesn't start with http or https then add it.
        if (!val.startsWith('http://') && !val.startsWith('https://')) {
            val = 'https://' + val;
        }

        // Getting the selection
        let activeObj = hoveredLinkCurrDataRef.current.hoveredObject;
        let isContinuous = false;
        let blockProcessId;
        let editingObj = activeObj;
        
        if (activeObj.type === 'group' && activeObj.editingTextObj) {
            isContinuous = true;
            blockProcessId = activeObj.editingProcessId;
        } else if (activeObj.type === 'textbox' && activeObj.uuid && activeObj.editingProcessId) {
            isContinuous = true;
            blockProcessId = activeObj.editingProcessId;
        } else if (activeObj.type === 'textbox' && activeObj.isTableTextbox) {
            isContinuous = true
            blockProcessId = activeObj.tableObject.editingProcessId;
            editingObj = activeObj.tableObject;
        } else if (activeObj.type === 'cellText') {
            editingObj = canvas.getObjects().find(o => o.uuid === activeObj.tableUuid);
        }

        if (isContinuous) {
            canvas.collaborationManager.addEditingMethod(blockProcessId, EDITING_METHODS.TEXT_HYPERLINK)
        } else {
            const { processId, aborted } = canvas.collaborationManager.startEditing(
                [editingObj],
                canvas.pageId,
                EDITING_METHODS.TEXT_HYPERLINK
            )
            if (aborted) {
                return;
            }
            blockProcessId = processId;
        }

        let sendUpdates = !(activeObj.type === 'group' && activeObj.editingTextObj);

        if (activeObj.type === 'textbox' && activeObj.isTableTextbox) {
            sendUpdates = false;
        }

        const textboxObj = getActiveTextboxObject(activeObj);
        const { selectionStart, selectionEnd } = textboxObj.getHoveredHyperlinkSelectionIndexes(
            hoveredLinkCurrDataRef.current.lineIndex,
            hoveredLinkCurrDataRef.current.linkStart,
            hoveredLinkCurrDataRef.current.linkEnd,
            canvas
        );

        if (selectionStart === -1) { return; }

        textboxObj.setSelectionStyles({
            url: val
        }, selectionStart, selectionEnd);

        if (activeObj.type === 'cellText') {
            const table = canvas.getObjects().find((o) => o.uuid === activeObj.tableUuid);
            if (table) {
                table.changeCellStyles(textboxObj.styles, true, activeObj.cellId);
                activeObj = table;
            }
        }

        if (!isContinuous) {
            canvas.collaborationManager.commitEditing(blockProcessId, canvas.pageId)
        } else {
            canvas.collaborationManager.updateContinuousEditing(blockProcessId)
        }
        if (sendUpdates) {sendUpdatesOfTargetObject(activeObj, textboxObj); }
        resetHyperlinkState();
        canvas.renderAll();
    }

    /**
     * Opened edit hyperlink input and focused it as default.
     */
    const editHyperLink = () => {
        if (!isUserHasAccessToFeature('hyperlink_edit', userAccess)) return;

        setIsEdit(true);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0)
    }

    /**
     * Removes the hyperlink from the textbox.
     */
    const removeHyperLink = () => {
        if (!isUserHasAccessToFeature('hyperlink_delete', userAccess)) return;

        let activeObj = hoveredLinkCurrDataRef.current.hoveredObject;

        let isContinuous = false;
        let blockProcessId;
        let editingObj = activeObj;

        if (activeObj.type === 'group' && activeObj.editingTextObj) {
            isContinuous = true;
            blockProcessId = activeObj.editingProcessId;
        } else if (activeObj.type === 'textbox' && activeObj.uuid && activeObj.editingProcessId) {
            isContinuous = true;
            blockProcessId = activeObj.editingProcessId;
        } else if (activeObj.type === 'textbox' && activeObj.isTableTextbox) {
            isContinuous = true
            blockProcessId = activeObj.tableObject.editingProcessId;
            editingObj = activeObj.tableObject;
        } else if (activeObj.type === 'cellText') {
            editingObj = canvas.getObjects().find(o => o.uuid === activeObj.tableUuid);
        }

        if (isContinuous) {
            canvas.collaborationManager.addEditingMethod(blockProcessId, EDITING_METHODS.TEXT_HYPERLINK)
        } else {
            const { processId, aborted } = canvas.collaborationManager.startEditing(
                [editingObj],
                canvas.pageId,
                EDITING_METHODS.TEXT_HYPERLINK
            )
            if (aborted) {
                return;
            }
            blockProcessId = processId;
        }
        editingObj.onShapeChanged()
        
        
        let sendUpdates = !(activeObj.type === 'group' && activeObj.editingTextObj);
        if (activeObj.isTableTextbox) {
            sendUpdates = false;
        }

        const textboxObj = getActiveTextboxObject(activeObj);

        let { selectionStart, selectionEnd } = textboxObj.getHoveredHyperlinkSelectionIndexes(
            hoveredLinkCurrDataRef.current.lineIndex,
            hoveredLinkCurrDataRef.current.linkStart,
            hoveredLinkCurrDataRef.current.linkEnd,
            canvas
        );

        if (selectionStart === -1 || selectionEnd === -1) return; 

        textboxObj.removeHyperLinkFromText(selectionStart, selectionEnd);

        // Remove hasHyperlink flag from object if there is no hyperlink after we remove above one.
        if (textboxObj.hyperLinkPositions.length === 1) {
            textboxObj.set({ hasHyperlink: false });

            if (activeObj.type === 'group') {
                activeObj.set({ hasHyperlink: false });
            }

            activeObj.hoverCursor = null;
        }

        if (activeObj.type === 'cellText' || activeObj.isTableTextbox) {
            const table = canvas.getObjects().find((o) => o.uuid === activeObj.tableUuid);
            if (table) {
                table.changeCellStyles(textboxObj.styles, true, activeObj.cellId);
                activeObj = table;

                if (textboxObj.hyperLinkPositions.length === 1) {
                    activeObj.set({ hasHyperlink: false });
                    activeObj.hoverCursor = null;
                }
            }
        }
        
        if (!isContinuous) {
            canvas.collaborationManager.commitEditing(blockProcessId, canvas.pageId)
        } else {
            canvas.collaborationManager.updateContinuousEditing(blockProcessId)
        }

        if (sendUpdates) {
            sendUpdatesOfTargetObject(activeObj, textboxObj);
        }

        resetHyperlinkState();
        canvas.renderAll();
    }

    const _debounceTriggerRenderCharacters = useCallback(debounce(triggerRenderCharacters, 100), []);

    /**
     * Listens the mouse move event. If the hovered target has hyperlink then continues to the process.
     * If mouse position is over the hyperlink, then we show the hyperlink box.
     * @param {{ target: fabric.Object, pointer: { x: number; y: number; }, e: MouseEvent }} opt 
     */
    const listenMouseMove = useCallback((opt) => {
        if (canvas.isDrawingMode === true || !shouldDisplayHyperlink) return;
        let isHyperlink = (opt.target && !!(opt.target.hasHyperlink || opt.target.group?.hasHyperlink || opt.target.editFor?.hasHyperlink));
        const hyperlinkBoxHeight = 46;
        const bottomOffset = 150;

        let hyperlinkedObjects = [];
        if (!isHyperlink && opt.target?.type === 'activeSelection') {
            const selectionObjects = opt.target.getObjects();
            hyperlinkedObjects = selectionObjects.filter((obj) => {
                return (obj && !!(obj.hasHyperlink || obj.group?.hasHyperlink || obj.editFor?.hasHyperlink));
            })

            isHyperlink = hyperlinkedObjects.length > 0;
        }

        if (opt.target && (opt.target.type === 'group' || hyperlinkedObjects.length > 0) && isHyperlink) {
            if (opt.target.type === 'group') {
                _debounceTriggerRenderCharacters(opt.target);
            } else {
                hyperlinkedObjects.forEach((obj) => {
                    if (obj.type !== 'group') return;
                    _debounceTriggerRenderCharacters(obj);
                });
            }
        }

        if (isHyperlink && ['group', 'textbox', 'table', 'activeSelection'].includes(opt.target.type)) {
            const pointer = canvas.getPointer(opt.e, false);
            let { url: link, lineIndex, lineHeight, selectionStart, selectionEnd, hoveredObject, ...position } = getHoveredHyperLink(opt.target, pointer, hyperlinkedObjects);
            selectedObjectRef.current = opt.target;

            let left = 0;
            let top = 0;

            const isLinkOrLinkPositionChanged = link !== hoveredLink || hoveredLinkCurrDataRef.current.lineIndex !== lineIndex;

            if (
                (link && !isTextboxSelected) ||
                (link && hoveredLink !== null && isLinkOrLinkPositionChanged && !isEdit)
            ) {
                const zoom = canvas.getZoom();
                let pos = (opt.pointer.y + hyperlinkBoxHeight + bottomOffset) > canvas.height ?
                    { ...position.tl, y: position.tl.y - ((hyperlinkBoxHeight - lineHeight) / zoom) }
                    : { ...position.bl, y: position.bl.y - (lineHeight * hoveredObject?.scaleY) }

                if (hoveredObject?.type === 'group' && hoveredObject?.realAngle >0) {
                    const objCenter = hoveredObject.getCenterPoint();

                    if (opt.target?.type === 'activeSelection') {
                        const selectionCenter = opt.target.getCenterPoint();
                        objCenter.x += selectionCenter.x;
                        objCenter.y += selectionCenter.y;
                    }

                    pos = rotatePoint(
                        pos.x,
                        pos.y,
                        objCenter.x,
                        objCenter.y,
                        hoveredObject.realAngle
                    );
                }
                
                const coords = fabric.util.transformPoint(pos, canvas.viewportTransform, false);
                left = coords.x;
                top = coords.y;


                opt.target.hoverCursor = 'pointer';
                setHoveredLink(link);
                setHoveredLinkPositions({ left, top });
                hoveredLinkCurrDataRef.current = {
                    lineIndex,
                    selectionStart: opt.selectionStart,
                    selectionEnd: opt.selectionEnd,
                    linkStart: selectionStart,
                    linkEnd: selectionEnd,
                    hoveredObject
                };
            } else if (!link && !isEdit) {
                opt.target.hoverCursor = opt.target.isEditing ? 'text' : (opt.panMode === true ? 'grab' : null);
                resetHyperlinkState();
            }
        } else if (hoveredLink !== null && !isEdit) {
            const target = opt.target || hoveredLinkCurrDataRef.current?.hoveredObject;
            if (target) {
                target.hoverCursor = target.isEditing ? 'text' : (opt.panMode === true ? 'grab' : null);
            }
            resetHyperlinkState();
        }
    }, [_debounceTriggerRenderCharacters, isTextboxSelected, hoveredLink, isEdit, canvas, resetHyperlinkState, shouldDisplayHyperlink])

    const onTextboxSelectionChanged = useCallback(({ target }) => {
        const selectionStart = target.selectionStart;
        let selectionEnd = target.selectionEnd;

        if (selectionStart !== selectionEnd) return;
 
        if (selectionStart === selectionEnd) {
            selectionEnd += 1;
        }

        const [charStyle] = target.getSelectionStyles(selectionStart, selectionEnd);

        if (
            isEdit &&
            (hoveredLinkCurrDataRef.current.selectionStart !== selectionStart || hoveredLinkCurrDataRef.current.selectionEnd !== selectionEnd) &&
            charStyle?.url !== hoveredLink
        ) {
            resetHyperlinkState();
        }
    }, [isEdit, hoveredLink])

    useEffect(() => {
        if (!canvas) { return; }
        canvas.on('hyperlink:mousemove', listenMouseMove)
        canvas.on('selection:created', () => forceRender(uuidv4()))
        canvas.on('selection:updated', resetHyperlinkState)
        canvas.on('selection:cleared', resetHyperlinkState)
        canvas.on('board:zoom', resetHyperlinkState)
        canvas.on('board:pan', resetHyperlinkState)
        canvas.on('hyperlink:remove', resetHyperlinkState)
        canvas.on('text:selection:changed', onTextboxSelectionChanged)
        canvas.on('hyperlink:table_cell_selected', () => forceRender(uuidv4()))

        return () => {
            canvas.off('hyperlink:mousemove', listenMouseMove);
            canvas.off('hyperlink:remove', listenMouseMove);
            canvas.off('hyperlink:table_cell_selected', listenMouseMove);
        }
    }, [canvas, listenMouseMove, onTextboxSelectionChanged, resetHyperlinkState]);

    const isLocked = selectedObjectRef.current
        ? (isTargetLocked(selectedObjectRef.current) && selectedObjectRef.current?.isEditing !== true)
        : false;

    return (
        <div
            className={clsx('hyperLinkBox', { '__is-hovered': hoveredLink !== null })}
            style={{
                left: `${hoveredLinkPositions?.left}px`,
                top: `${hoveredLinkPositions?.top}px`
            }}
        >
            {(isEdit && isLocked !== true) ? (
                <input defaultValue={hoveredLink} onKeyDown={onInputKeyDown} ref={inputRef} />
            ) : (
                <a href={hoveredLink} rel="noreferrer" target="_blank">{hoveredLink?.length > 30 ? `${hoveredLink.substring(0, 30)}...` : hoveredLink}</a>
            )}

            {(isTextboxSelected && isLocked !== true) ? <div className="actionButtons">
                {isEdit ? (
                    <button
                        type="button"
                        disabled={!isUserHasAccessToFeature('hyperlink_apply', userAccess)}
                        onClick={applyNewLink}
                    >
                        <em className="icon-tick-circle" />
                    </button>
                ) : (
                    <>
                        <button
                            type="button"
                            disabled={!isUserHasAccessToFeature('hyperlink_edit', userAccess)}
                            onClick={editHyperLink}
                        >
                            <em className="icon-pencil" />
                        </button>

                        <button
                            type="button"
                            disabled={!isUserHasAccessToFeature('hyperlink_delete', userAccess)}
                            onClick={removeHyperLink}
                        >
                            <em className="icon-trash" />
                        </button>
                    </>
                )}
            </div> : null}
        </div>
    )
};

HyperLinkBox.propTypes = {
    canvas: PropTypes.object,
    actionSelected: PropTypes.shape({
        selectMode: PropTypes.bool,
        lassoMode: PropTypes.bool,
        dragMode: PropTypes.bool,
        commentMode: PropTypes.bool,
        pencilMode: PropTypes.bool,
        eraseMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        rectMode: PropTypes.bool,
        ellipseMode: PropTypes.bool,
        triangleMode: PropTypes.bool,
        arrowMode: PropTypes.bool,
        lineMode: PropTypes.bool,
        stickyMode: PropTypes.bool,
        frameMode: PropTypes.bool,
        textMode: PropTypes.bool,
        undoMode: PropTypes.bool,
        redoMode: PropTypes.bool,
        tableMode: PropTypes.bool
    })
}

export default HyperLinkBox;