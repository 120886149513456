import React, {useState, useEffect, useCallback} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './RequestEdit.scss';

import CloseIcon from '../svgIcons/Close';
import Spinner from '../svgIcons/Spinner';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { closeRequestAccessToast } from '../../helpers/ToastHelper';
import SelectInput from '../inputs/select/SelectInput';
import {USER_ROLES} from '../../helpers/Constant';
import {acceptRequestAccess} from '../../services/RequestAccessService';
import eventEmitter from '../../helpers/EventEmitter';

const RequestEditApproval = ({requestEditUserRef, deniedUserRef}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [creatingRequestEdit, setCreatingRequestEdit] = useState(false);
    const [upgradedPermission, setUpgradedPermission] = useState('edit');
    const [userInfo, setUserInfo] = useState(requestEditUserRef.current);
    
    const { requestList, users } = useSelector((state) => state?.board);
    
    const handleShowInviteModal = () => {
        closeRequestAccessToast();
        setTimeout(() => {
            dispatch({
                type: 'modal/toggleInvite',
                payload: {
                    shouldShow: true
                }
            })
        }, 0);
    }
    
    const handleShowDenyEditAccessModal = () => {
        deniedUserRef.current = requestEditUserRef?.current;
        closeRequestAccessToast();
        setTimeout(() => {
            dispatch({
                type: 'modal/toggleRequestUpgradeDenyModal',
                payload: {
                    shouldShow: true
                }
            })
        }, 0);
    }
    
    const {
        whiteBoardSlugId,
    } = useSelector((state) => state?.board);
    
    const joinedUserOptions = useCallback(() => {
        let roles = Object.values(USER_ROLES).filter((role) => role.id !== USER_ROLES.removeAccess.id);
        
        return roles;
    }, [requestEditUserRef]);
    
    const onRequestPermissionChange = (userId, permission) => {
        setUpgradedPermission(permission.id)
        const editedList = requestList.map(item => {
            if (item.userId === userId) return { ...item, permission: permission.id }
            else return item
        })
        dispatch({
            type: 'board/changeBoardDetails',
            payload: {requestList: editedList}
        })
    }
    
    const handleRequestEditApproval = async () => {
        setCreatingRequestEdit(true);
        let isError = false;
        try {
            const response = await acceptRequestAccess(whiteBoardSlugId, { requestId: requestEditUserRef.current.requestId, permission: upgradedPermission, isUpgrade: true });
            if(response?.success){
                setCreatingRequestEdit(false);
                handleShowInviteModal();
                
                dispatch({
                    type: 'board/changeBoardDetails',
                    payload: {users: [...users.map(item => {
                        return item.id === requestEditUserRef.current.id ? {...item, isUpgrade: false, info: {...item.info, permission: upgradedPermission}} : item
                    })]}
                })
            }
        }
        catch (err) {
            isError = true;
            console.log(err)
        }
        finally {
            if (isError) {
                console.log(isError, '')
            }
        }
    }
    
    useEffect(() => {
        requestEditUserRef.current =  userInfo
    }, [userInfo])
    
    useEffect(() => {
        setIsLoading(false);
        
        const handlePermissionUpdate = (data) => {
            if(requestEditUserRef?.current?.id === data.userId && data.permission === 'edit') handleShowInviteModal();
        }
        
        const handleGetUserInfo = (data) => {
            setUserInfo(users.find(item => item.id === data.userId));
            requestEditUserRef.current = users.find(item => item.id === data.userId);
        }
        
        const handleApprovalCheck = (data) => {
            if(!data.requests?.find(item => item.userId === requestEditUserRef.current.id)) handleShowInviteModal();
        }
         
        eventEmitter.on('permissionUpdate', handlePermissionUpdate);
        eventEmitter.on('requestUpgradeUser', handleGetUserInfo);
        eventEmitter.on('updateRequestList', handleApprovalCheck);
        
        return () => {
            eventEmitter.off('permissionUpdate', handlePermissionUpdate);
            eventEmitter.off('requestUpgradeUser', handleGetUserInfo);
            eventEmitter.off('updateRequestList', handleApprovalCheck);
        }
    },[userInfo])
    
    return (
        <section className={clsx('requestEditModal', { loading: isLoading })}>
            {isLoading ? (
                <Spinner height={40} width={40} />
            ) : (
                <>
                    <header>
                        <h2>Request to upgrade access</h2>
                        
                        <button type="button" data-testid="closeBtn" onClick={handleShowInviteModal}>
                            <CloseIcon height={18} width={18} />
                        </button>
                    </header>
                    <main>
                        <span className='requestEditModal--info'>
                            <div className="user-avatar">{requestEditUserRef?.current?.commentUsername.slice(0, 1)}</div> <div><span className='user-info'>{requestEditUserRef?.current?.commentUsername} ({requestEditUserRef?.current?.email})</span> currently has <span className='user-info permission'>{requestEditUserRef?.current?.info?.permission}</span> access and is requesting to upgrade their access</div>
                        </span>
                        {
                            requestEditUserRef?.current?.message ? <span className="requestEditModal--message">
                                {requestEditUserRef?.current?.message}
                            </span> : null
                        }
                        <div className='requestEditModal--permission-wrapper'>
                            <div className="requestEditModal--permission-text">
                                Requested access
                            </div>
                            <SelectInput
                                defaultValue='edit'
                                value={upgradedPermission}
                                disabledOptions={[requestEditUserRef?.current?.info?.permission]}
                                onChangeItem={(opt) => onRequestPermissionChange(requestEditUserRef?.current?.userId, opt)}
                                options={joinedUserOptions().slice(0, -1)}
                                placement="bottom-end"
                            />
                        </div>
                        <fieldset className="requestEditModal--form">
                            <button
                                className="cancel-request-edit-button approval"
                                data-testid="cancel-request-edit-button"
                                onClick={handleShowDenyEditAccessModal}
                                type="submit"
                            >
                                Deny
                            </button>
                            <div className='request-edit-button-wrapper'>
                                <button
                                    className="request-edit-button approval"
                                    data-testid="request-edit-button"
                                    onClick={handleRequestEditApproval}
                                    type="submit"
                                >
                                    <ClipLoader color="#fff" loading={creatingRequestEdit} size={22}/> {creatingRequestEdit ? '' : 'Accept'}
                                </button>
                            </div>
                        </fieldset>
                    </main>
                </>
            )}
        </section>
    );
}

RequestEditApproval.propTypes = {
    requestEditUserRef: PropTypes.shape({
        current: PropTypes.object
    }),
    deniedUserRef: PropTypes.shape({
        current: PropTypes.object
    })
}

export default RequestEditApproval;