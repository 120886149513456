import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '../../tooltip/Tooltip';
import './HeaderCollaborators.scss';
import clsx from 'clsx';
import useOnClickOutside from '../../../hooks/UseOutsideClick';

const MAX_VISIBLE_COLLABORATORS_COUNT = 4;
const COLORS = ['#7C4DFF', '#FFAB00', '#00C853', '#304FFE']

const HeaderCollaborators = () => {
    const [isListOpen, setIsListOpen] = useState();
    const [hoveredUser, setHoveredUser] = useState({});
    const collaborators = useSelector((state) => state.collaborators);
    const { users } = useSelector((state) => state.board);
    const visibleCollaborators = collaborators.slice(0, MAX_VISIBLE_COLLABORATORS_COUNT);
    const listRef = useRef(null);

    const toggleListVisibility = () => {
        setIsListOpen((state) => !state);
    }

    const handleClickOutside = () => {
        setIsListOpen(false);
    }

    const onUserHover = (event) => {
        if (!event?.target) return;
        const pos = event.target.getBoundingClientRect();

        setHoveredUser({
            left: pos.left + (pos.width / 2),
            top: pos.bottom + 10,
            userId: parseInt(event.target.getAttribute('userId'), 10)
        });
    }

    const onUserLeave = (event) => {
        if (!event?.target) return;
        setHoveredUser({});
    }
    
    const getUserName = (data) => {
        return users.find(item => item.id === data.userId)?.name || ''
    }

    useOnClickOutside(listRef, handleClickOutside);

    if (collaborators?.length === 0) return null;

    return (
        <div id="collaborators-wrapper">
            <div className="avatar-wrapper">
                {visibleCollaborators.map((collaborator, idx) => (
                    <div className="avatar" key={collaborator.id || idx} style={{ backgroundColor: COLORS[idx] }}>
                        {getUserName(collaborator) ? getUserName(collaborator)[0] : null}

                        <Tooltip text={getUserName(collaborator)} position="bottom" />
                    </div>
                ))}
            </div>

            {(collaborators.length > MAX_VISIBLE_COLLABORATORS_COUNT) ? (
                <div className="more-collaborators">
                    <button
                        className={clsx('count', { __active: isListOpen })}
                        type="button"
                        onClick={toggleListVisibility}
                    >
                        +{collaborators.length - visibleCollaborators.length}
                    </button>

                    <div
                        className={clsx('list', { __active: isListOpen })}
                        ref={listRef}
                    >
                        {collaborators.slice(MAX_VISIBLE_COLLABORATORS_COUNT).map((collaborator, idx) => (
                            <div className="list-item" key={collaborator.id || idx}>
                                <div className="avatar" style={{ backgroundColor: COLORS[idx % 4]}}>
                                    {getUserName(collaborator) ? getUserName(collaborator)[0] : null}
                                </div>

                                <span
                                    className="user-name"
                                    // eslint-disable-next-line
                                    userId={collaborator.id}
                                    onMouseOver={getUserName(collaborator).length > 15 ? onUserHover : null}
                                    onMouseLeave={getUserName(collaborator).length > 15 ? onUserLeave : null}
                                >
                                    {getUserName(collaborator).slice(0, 15)}
                                    {getUserName(collaborator).length > 15 ? '...' : null}

                                    {hoveredUser?.userId === collaborator.id ? (
                                        <Tooltip text={getUserName(collaborator)} position="bottom" usePortal={true} dimensions={hoveredUser} />
                                    ) : null}
                                </span>

                            </div>
                        ))}    
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default HeaderCollaborators;