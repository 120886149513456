
const editCurvedLineText = (event) => {
    const { target } = event;
    // if (target.isTextEnabled()) {
    //     const textPos = target._getTextPos();
    //     const actualPoint = {
    //         x1: target.left + textPos.tl.x - target.pathOffset.x,
    //         y1: target.top + textPos.tl.y - target.pathOffset.y,
    //         x2: target.left + textPos.tl.x - target.pathOffset.x + textPos.width,
    //         y2: target.top + textPos.tl.y - target.pathOffset.y + textPos.height
    //     }
    //     const clickedPointer = target.canvas.getPointer(event);
    //     if (
    //         (clickedPointer.x >= actualPoint.x1 && clickedPointer.x <= actualPoint.x2) && 
    //     (clickedPointer.y >= actualPoint.y1 && clickedPointer.y <= actualPoint.y2)) {
    //         target.canvas.fire('open_text_editor', target);
    //         return;
    //     }
    // }
    target.canvas.fire('open_text_editor', target);
}

export default editCurvedLineText;