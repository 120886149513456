import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeRequestAccessToast } from '../helpers/ToastHelper';


const useOverlay = (showOverlay, setShowOverlay, isClickable = true, showDenyOverlay, showRequestAccessUpgradeOverlay, showRequestEditApprovalOverlay) => {
    const dispatch = useDispatch();
    const [overlayNode, setOverlayNode] = React.useState(null),
        [addedNode, setAddedNode] = React.useState(false);

    React.useEffect(() => {
        let divWrapper = document.createElement('div');
        divWrapper.classList.add('siteOverlay');
        setOverlayNode(divWrapper);
    }, [])


    const closeOverlay = useCallback((forceToClose) => {
        if ((showOverlay || showRequestAccessUpgradeOverlay) && (forceToClose || isClickable)) {
            if (addedNode) {
                try {
                    document.body.removeChild(overlayNode);
                } catch (err) {
                    console.error('Error happened when removing overlay', err);
                }
            }
        }
    }, [showOverlay, showDenyOverlay, showRequestAccessUpgradeOverlay, showRequestEditApprovalOverlay, setShowOverlay, addedNode, overlayNode, isClickable]);

    React.useEffect(() => {
        const handleKeyDown = e => {
            if (e.key === 'Escape') {
                closeOverlay();
                if (isClickable) { setShowOverlay(false); }
            }
        }
        const handleOverlayClick = () => {
            if (document.querySelector('.denyAccessModal') || document.querySelector('.requestEditModal')) {
                document.removeEventListener('click', handleOverlayClick)
                closeRequestAccessToast();
                setTimeout(() => {
                    dispatch({
                        type: 'modal/toggleInvite',
                        payload: {
                            shouldShow: true
                        }
                    })
                }, 100);

            }
            else {
                if(isClickable) setShowOverlay(false);
                closeOverlay();
            }
        }
        if ((showOverlay || showDenyOverlay || showRequestAccessUpgradeOverlay || showRequestEditApprovalOverlay) && overlayNode) {
            document.body.appendChild(overlayNode);
            overlayNode.addEventListener('click', handleOverlayClick);
            document.addEventListener('keydown', handleKeyDown);
            setAddedNode(true);
        } else {
            if (addedNode) {
                closeOverlay();
                overlayNode.removeEventListener('click', handleOverlayClick)
                document.removeEventListener('keydown', handleKeyDown);
            }
        }

        return () => {
            if (addedNode) {
                closeOverlay(true); // Overlay should be closed even if clickable feature is not enabled.
                document.removeEventListener('click', handleOverlayClick);
            }
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [showOverlay, overlayNode, addedNode, closeOverlay, isClickable])
    return closeOverlay
}


export default useOverlay;