import axios from 'axios';
import { apiEndpoints } from '../helpers/Constant';
import { environment } from '../environment';
/**
 * @param wbId
 */
export async function checkRequestAccess(wbId) {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.checkRequestAccess.replace('{wbId}', wbId));
        return response;
    } catch (error) {
        return error
    }
}

/**
 * @param wbId
 * @param message
 * @param isEdit
 * @param isPublic
 */
export async function sendRequestAccess(wbId, message, isEdit, isPublic) {
    try {
        let payload = {};
        if(message) payload = {message}
        if(isEdit) payload = {...payload, isUpgrade: true}
        else if(isPublic) payload = { ...payload, isPublic: true }
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.sendRequestAccess.replace('{wbId}', wbId), payload);
        return response;

        
    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}

/**
 * @param wbId
 * @param message
 */
export async function pendingRequestList(wbId) {
    try {
        const response = await axios.get(environment.REACT_APP_API_ENDPOINT + apiEndpoints.pendingRequestList.replace('{wbId}', wbId));
        return response;


    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}

/**
 * @param wbId
 * @param payload
 */
export async function acceptRequestAccess(wbId,payload) {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.acceptRequestAccess.replace('{wbId}', wbId), payload);
        return response;


    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}

/**
 * @param wbId
 * @param payload
 */
export async function rejectRequestAccess(wbId, payload) {
    try {
        const response = await axios.post(environment.REACT_APP_API_ENDPOINT + apiEndpoints.rejectRequestAccess.replace('{wbId}', wbId), payload);
        return response;


    } catch (error) {
        return {
            'errorCode': error.code,
            'error': error.response.data.message
        };
    }
}