import {EDITING_METHODS, LINE_POLYGON_DIRECTIONS, LINE_POLYGON_POINTS} from '../Constant';
import { calculateObjectCenterPoint } from '../FabricMethods';
import { customContainPointsWidthPadding, getLinePointIndexFromSide } from './LineMethods';
import {rotatePointBack} from './AttachedObjectTransformHandlers';


// handle reattaching
const handleReAttach = (line, polygon, side, pointIndex, actualPoint) => {
    const relativeSideX = `${side}RelativeX`;
    const relativeSideY = `${side}RelativeY`;

    const polygonCenter = calculateObjectCenterPoint(polygon);
    const rotated = rotatePointBack(
        actualPoint.x,
        actualPoint.y,
        polygonCenter.x,
        polygonCenter.y,
        polygon.angle
    )

    const ratio = line.calculateRelativeRatio(rotated.x, rotated.y, polygonCenter, polygon);
    line[relativeSideX] = ratio.x;
    line[relativeSideY] = ratio.y;
    
    return true;
}

/**
 * Controls if the line is detached from the polygon 
 * or reattaches the line to the its polygons with the current points.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.CurvedLine} line 
 * @param {object} options Options for controlling.
 */
export default function detachControl(canvas, line, options = {}) {
    let isReAttached = false;

    for (const side of LINE_POLYGON_POINTS) {
        try {
            const polygonName = `${side}Polygon`;
            const polygon = line[polygonName];
            if (!polygon) continue;
            const polygonInCanvas = canvas.getObjects().find(obj => obj.uuid && obj.uuid === polygon?.uuid);
            if (!polygonInCanvas) continue;
            
            const pointIndex = getLinePointIndexFromSide(side, line);
            if (pointIndex === -1) continue;
    
            const actualPoint = {
                x: line.left + line.points[pointIndex].x - line.pathOffset.x,
                y: line.top + line.points[pointIndex].y - line.pathOffset.y
            }
    
            if (line.reattachAfterModify) {
                handleReAttach(line, polygonInCanvas, side, pointIndex, actualPoint);        
                isReAttached = true;
            } else {
                const isStillAttached = customContainPointsWidthPadding(polygonInCanvas, actualPoint, 5);
                if (isStillAttached) {
                    continue;
                }
                
                // update the object with collaboration manager
                if (options.isContinuous && options.processId) {
                    canvas.collaborationManager.addLaterShapes(
                        [polygonInCanvas],
                        options.processId,
                        EDITING_METHODS.ATTACHMENT_LINE
                    )
                }

                if (polygonInCanvas.lines) polygonInCanvas.lines = polygonInCanvas.lines.filter(lineUuid => lineUuid !== line.uuid);
                // canvas.fire('history-emit-data', {action: 'modified', objects: [polygonInCanvas]});

                if (options.isContinuous && options.processId) {
                    const editingMap = new Map()
                    const editingMethod = new Set();
                    editingMethod.add(side === LINE_POLYGON_DIRECTIONS.LEFT
                        ? EDITING_METHODS.LINE_POLYGON_LEFT : EDITING_METHODS.LINE_POLYGON_RIGHT)
                    editingMap.set(line, editingMethod)
                    canvas.collaborationManager.addIsolatedEditingMethod(
                        options.processId,
                        editingMap
                    )
                }
                
                line[polygonName] = null;

                // if the line is a hotspot, check if its detached from the other polygons
                // if its, delete the flowchart props
                if (polygonInCanvas.flowchartProps && line.flowchartProps) {
                    try {
                        if (line?.flowchartProps[`${side}flowchartName`]) {
                            delete line.flowchartProps[`${side}flowchartName`];
                        }
                        const contrastSide = side === 'left' ? 'right' : 'left';

                        if (!line?.flowchartProps[`${contrastSide}flowchartName`]) { 
                            delete line.flowchartProps;
                        }
                    } catch (e) {
                        console.log('error while handling line detach control for flowcharts', e);
                    }

                }
            }
        } catch (error) {
            console.error('error while handling line detach control', error)
        }
    }

    // if the line is reattached, we don't need to reattach it again in the next calls
    if (isReAttached) {
        line.reattachAfterModify = false;
    }
}