import { useEffect } from 'react';
import {useSelector} from 'react-redux';

/**
 * @param {boolean|undefined} isDynamic
 */
const useDocumentTitle = ({isDynamic}) => {
    const {boardName} = useSelector(state => state?.board);
    useEffect(() => {
        let title = ''
        if(isDynamic && boardName !== '') title = `WB - ${boardName}`;
        else title = 'Builder Whiteboard';
        document.title = title;
    }, [boardName]);
};

export default useDocumentTitle;