import { fabric } from 'fabric';
import { isLineAttachedToElement } from '../lines/LineMethods';
import {deepClone} from '../CommonUtils';

/**
 * Override of fabric.util.applyTransformToObject to remove scale and angle from lines .
 * @override
 * @param {fabric.Object} object - Object to apply transform to.
 * @param {object} transform - Transform to apply.
 */
export function applyTransformToObject(object, transform) {
    const options = fabric.util.qrDecompose(transform),
        center = new fabric.Point(options.translateX, options.translateY);
    if (object?.shapeType === 'curvedLine') {
        options.scaleX = 1;
        options.scaleY = 1;
        options.angle = 0;
    }
        
    object.flipX = false;
    object.flipY = false;
    object.set('scaleX', options.scaleX);
    object.set('scaleY', options.scaleY);
    object.skewX = options.skewX;
    object.skewY = options.skewY;
    object.angle = options.angle;
    object.setPositionByOrigin(center, 'center', 'center');
}


/**
 * Overrides fabric.util.saveObjectTransform to save center point of the object .
 * @override
 * @param {fabric.Object} target - The object that is being transformed.
 */
export function saveObjectTransform (target) {
    const originalValues = {
        scaleX: target.scaleX,
        scaleY: target.scaleY,
        skewX: target.skewX,
        skewY: target.skewY,
        angle: target.angle,
        left: target.left,
        flipX: target.flipX,
        flipY: target.flipY,
        top: target.top,
        width: target.width,
        height: target.height,
        center: target.getCenterPoint(), // new
    };
    if (target.lines && target.lines.length) {
        for (const lineUUID of target.lines) {
            const lineInCanvas = target.canvas.getObjects().find(
                obj => obj.uuid === lineUUID
            )
            if (lineInCanvas && isLineAttachedToElement(lineInCanvas, target)) {
                originalValues[lineUUID] = {
                    leftRelativeX: lineInCanvas.leftRelativeX,
                    leftRelativeY: lineInCanvas.leftRelativeY,
                    rightRelativeX: lineInCanvas.rightRelativeX,
                    rightRelativeY: lineInCanvas.rightRelativeY,
                }
            }
        }
    }
    
    if (target.type === 'activeSelection') {
        originalValues.objects = new Map();
        target.forEachObject(obj => {
            originalValues.objects.set(obj, saveObjectTransform(obj));
        });
    }
    
    if (target.type === 'curvedLine') {
        originalValues.points = deepClone(target.points);
    }
    
    return originalValues;

}


/**
 * @param {object} prevStyle - First style to compare.
 * @param {object} thisStyle - Second style to compare.
 * @param {boolean} forTextSpans - Whether to check overline, underline, and line-through properties.
 * @returns {boolean} True if the style changed.
 * @memberOf fabric.util
 */
export function hasStyleChanged (prevStyle, thisStyle, forTextSpans) {
    forTextSpans = forTextSpans || false;
    return (prevStyle.fill !== thisStyle.fill ||
          prevStyle.stroke !== thisStyle.stroke ||
          prevStyle.strokeWidth !== thisStyle.strokeWidth ||
          prevStyle.fontSize !== thisStyle.fontSize ||
          prevStyle.fontFamily !== thisStyle.fontFamily ||
          prevStyle.fontWeight !== thisStyle.fontWeight ||
          prevStyle.fontStyle !== thisStyle.fontStyle ||
          prevStyle.textBackgroundColor !== thisStyle.textBackgroundColor ||
          prevStyle.isHyperlink !== thisStyle.isHyperlink ||
          prevStyle.url !== thisStyle.url ||
          prevStyle.deltaY !== thisStyle.deltaY) ||
          (forTextSpans &&
            (prevStyle.overline !== thisStyle.overline ||
            prevStyle.underline !== thisStyle.underline ||
            prevStyle.linethrough !== thisStyle.linethrough));
}