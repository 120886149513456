import {getLocalPoint, isTransformCentered, wrapWithFireEvent} from '../CommonControlHelpers';


// get position of the target by origin
// in action handler, the calculations are made according to left, top
const setExpansionPositionByOrigin = (target, coordinate = 'x', pos = {}) => {
    const { originX, originY } = target;

    if (coordinate === 'x') {
        if (originX === 'center') {
            return pos.left + (target.getScaledWidth() / 2);
        } else if (originX === 'right') {
            return pos.left + target.getScaledWidth();
        }
        return pos.left;
    } else if (coordinate === 'y') {
        if (originY === 'center') {
            return pos.top + (target.getScaledHeight() / 2);
        } else if (originY === 'bottom') {
            return pos.top + target.getScaledHeight();
        }
        return pos.top;
    }
}


/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
export function _actionHandler(e, transform, x, y) {
    const isActionPerformed = expanseXAction(e, transform, x, y);
    if (isActionPerformed) {
        const { target } = transform;
        const objects = target.getObjects();
        for (const obj of objects) {
            const objPos = {}
            if (obj.valuesBeforeChange.aCoords.tl.x === - target.valuesBeforeChange.width / 2) {
                objPos.left = -target.width / 2;
            } else {
                objPos.left = obj.valuesBeforeChange.aCoords.tr.x * target.width / target.valuesBeforeChange.width - obj.getScaledWidth();
            }
            const leftPos = setExpansionPositionByOrigin(obj, 'x', objPos);
            obj.set(({left: leftPos}));
            obj.setCoords();
        }
    }
    return isActionPerformed;
}
/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function _actionHeightHandler(e, transform, x, y) {
    const isActionPerformed = expanseYAction(e, transform, x, y);
    if (isActionPerformed && !transform.target.valuesBeforeChange.isEqualY) {
        const { target } = transform;
        const objects = target.getObjects();
        for (const obj of objects) {
            const objPos = {}
            if (obj.valuesBeforeChange.aCoords.tl.y === - target.valuesBeforeChange.height / 2) {
                objPos.top = -target.height / 2;
            } else {
                objPos.top = obj.valuesBeforeChange.aCoords.bl.y * target.height / target.valuesBeforeChange.height - obj.getScaledHeight();
            }
            const topPos = setExpansionPositionByOrigin(obj, 'y', objPos);
            obj.set(({top: topPos}));
            obj.setCoords();
        }
    }
    return isActionPerformed 
}

/**
 * @param eventData
 * @param transform
 * @param x
 * @param y
 */
function expanseXAction(eventData, transform, x, y) {
    const corner = transform.corner;
    let originX = transform.originX;
    if (corner === 'expanseLeft') {
        originX = 'right'; 
    }
    const target = transform.target, localPoint = getLocalPoint(transform, originX, transform.originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleX : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldWidth = target.width,
        newWidth = Math.abs(localPoint.x * multiplier / target.scaleX) - strokePadding;
    target.set('width', Math.max(newWidth, 0));
    return oldWidth !== newWidth;
}
/**
 * @param e
 * @param transform
 * @param x
 * @param y
 */
function expanseYAction(e, transform, x, y) {
    const corner = transform.corner;
    let originY = transform.originY;
    if (corner === 'expanseTop') {
        originY = 'bottom'; 
    }
    const target = transform.target, localPoint = getLocalPoint(transform, transform.originX, originY, x, y),
        strokePadding = target.strokeWidth / (target.strokeUniform ? target.scaleY : 1),
        multiplier = isTransformCentered(transform) ? 2 : 1,
        oldHeight = target.height,
        newHeight = Math.abs(localPoint.y * multiplier / target.scaleY) - strokePadding;
  
    const height = Math.max(newHeight, 0);
  
    target.set({
        height,
    });
  
    return oldHeight !== newHeight;
}


export const expanseXHandler =  wrapWithFireEvent('expansing', _actionHandler);
export const expanseYHandler = wrapWithFireEvent('expansing', _actionHeightHandler);