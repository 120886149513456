import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    loggedInUser,
} from '../../services/AuthService';
import getToastIcon from '../../helpers/media/GetToastIcon';
import { toast } from 'react-toastify';
import './Auth.scss';
import getParamFromURL from '../../helpers/IframeHelper';
import { AUTH_VALIDATION_MESSAGE } from '../../helpers/Constant';
import AuthBaseScreen from '../authBaseScreen/AuthBaseScreen';
import Login from './Login';
import Register from './Register';
import useDocumentTitle from '../../hooks/UseDocumentTitle';

const Auth = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useDocumentTitle({isDynamic:true});

    const navigateToBoards = useCallback(() => {
    // if the user is coming from a share link, then redirect to the board with the shape
        const savingShapeUuid = getParamFromURL('shape', { testWithUuid: false });
        const pageId = getParamFromURL('pageId');
        const boardId = getParamFromURL('board');
        const urlParams = new URLSearchParams(window.location.search);

        if (savingShapeUuid && boardId) {
            let url = `/board/${boardId}?shape=${savingShapeUuid}`;
            if (pageId) {
                url += `&pageId=${pageId}`;
            }

            navigate(url);
        } else if (urlParams.has('callback')) {
            const link = decodeURIComponent(urlParams.get('callback'));
            navigate(link || '/boards');
        } else {
            navigate('/boards');
        }
    }, [navigate]);

    (0, useEffect)(() => {
        if (loggedInUser()) {
            navigateToBoards();
        }
    }, [navigateToBoards]);

    const [isLoginPage, setLoginPage] = useState(true)
    const resetPasswordResult = location.state;
    
    useEffect(() => {
        if (resetPasswordResult && resetPasswordResult.resetPassword === true) {
            toast.success(AUTH_VALIDATION_MESSAGE.PASSWORD_RESET_SUCCESSFULLY, {
                icon: getToastIcon('success'),
                className: 'wb_toast',
            });
        }
    }, [])

    return (
        <AuthBaseScreen toastClass={!isLoginPage ? 'auth-toast-container' : ''} pageTitle={isLoginPage ? 'Login' : 'Sign up'}>
            {isLoginPage ?
                <Login navigateToBoards={navigateToBoards} setLoginPage={setLoginPage} />
                :
                <Register setLoginPage={setLoginPage} />
            }
        </AuthBaseScreen>
    );
}

export default Auth;